/** 
* 自动生成文件，请勿修改 
* @name: 系统:菜单管理-系统菜单管理模块
* @description: 系统菜单管理模块-增删改查
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:10条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const menu = { 
	/** 1 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/menu/batchDelete.do"), 
	/** 2 summary:查询-当前登录用户有权限的菜单	method:post */ 
	queryUserMenuTree : new APIObject("/api/menu/queryUserMenuTree.do"), 
	/** 3 summary:查询-所有菜单树及操作码	method:post */ 
	queryTreeOp : new APIObject("/api/menu/queryTreeOp.do"), 
	/** 4 summary:删除-单条菜单	method:post */ 
	delete : new APIObject("/api/menu/delete.do"), 
	/** 5 summary:查询-查询单条菜单	method:post */ 
	queryInfoId : new APIObject("/api/menu/queryInfoId.do"), 
	/** 6 summary:查询-所有菜单树	method:post */ 
	queryTree : new APIObject("/api/menu/queryTree.do"), 
	/** 7 summary:修改菜单-单条	method:post */ 
	update : new APIObject("/api/menu/update.do"), 
	/** 8 summary:查询-根据父菜单id查询子菜单（分页）	method:post */ 
	queryPage : new APIObject("/api/menu/queryPage.do"), 
	/** 9 summary:查询-根据角色id查询有权限的菜单(及有权限的操作按钮)	method:post */ 
	queryMenuByRole : new APIObject("/api/menu/queryMenuByRole.do"), 
	/** 10 summary:新增菜单-单个	method:post */ 
	save : new APIObject("/api/menu/save.do"), 
};
export default menu;