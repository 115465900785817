/** 
* 自动生成文件，请勿修改 
* @name: 考核:员工年度考核维度得分表
* @description: 员工年度考核维度得分表（三期新增）
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:11条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const assessmentYear = { 
	/** 1 summary:同意/不同意-多条id,逗号分隔	method:post */ 
	agree : new APIObject("/api/assessmentYear/agree.do"), 
	/** 2 summary:查询-（需要自己打分的）当前登录用户为评分人	method:post */ 
	queryByJudgeUser : new APIObject("/api/assessmentYear/queryByJudgeUser.do"), 
	/** 3 summary:同意/不同意-当年考核表的所有打分项都同意/不同意	method:post */ 
	agreeAll : new APIObject("/api/assessmentYear/agreeAll.do"), 
	/** 4 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/assessmentYear/batchDelete.do"), 
	/** 5 summary:查询-单条(根据主键)	method:post */ 
	queryOne : new APIObject("/api/assessmentYear/queryOne.do"), 
	/** 6 summary:修改：多条	method:post */ 
	batchUpd : new APIObject("/api/assessmentYear/batchUpd.do"), 
	/** 7 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/assessmentYear/delete.do"), 
	/** 8 summary:生成员工考核维度表	method:post */ 
	generate : new APIObject("/api/assessmentYear/generate.do"), 
	/** 9 summary:查询-当前登录用户（被考核人自己的）	method:post */ 
	queryAllCurrentUser : new APIObject("/api/assessmentYear/queryAllCurrentUser.do"), 
	/** 10 summary:查询-全部不分页	method:post */ 
	queryAll : new APIObject("/api/assessmentYear/queryAll.do"), 
	/** 11 summary:修改：单条	method:post */ 
	update : new APIObject("/api/assessmentYear/update.do"), 
};
export default assessmentYear;