<!--
* @program: office_automation
* @author: ly
* @component:workerProcessShowerAll
* @description: 全体员工工作日历
* @create: 2022-01-11 17:29
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
                    <template #icon>
                        <SyncOutlined/>
                    </template>
                </a-button>
                <div style="margin: 10px;color: black">部门:</div>
                <a-select v-model:value="defaultQuery.departmentId"
                          style="width: 200px"
                          class="search-item"
                          @change="selectDepartment"
                >
                    <a-select-option v-for="department in departments" :key="department.id">
                        {{ department.name }}
                    </a-select-option>
                </a-select>
                <a-month-picker   @change="changeMonth" placeholder="导出月份"/>
                <a-button type="primary" @click="exoprtExcel" style="margin-left: 10px">
                    导出工作安排
                </a-button>
                <br/>
            </div>
            <TwoParts ref="TwoParts" pos="transform" :enable-change="false">
                <template #left>
                    <ArBaseTableFilter :view-model="true" :page-size="pageSize" :argument="argument">
                        <template #action="{record}">
                            <div class="action-column">
                                <a-tooltip title="工作安排">
                                    <a-button class="action-button" type="link" @click="workArrange(record)">
                                        <SearchOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-tooltip title="工作日志记录">
                                    <a-button class="action-button" type="link" @click="workDiary(record)">
                                        <CalendarOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                                <a-tooltip title="绩效分配">
                                    <a-button class="action-button" type="link" @click="moneyRecord(record)">
                                        <AccountBookOutlined class="pointer"/>
                                    </a-button>
                                </a-tooltip>
                            </div>
                        </template>
                    </ArBaseTableFilter>
                </template>
                <template #right>
                    <div v-is="compType" :userId="userId"/>
                </template>
            </TwoParts>
        </div>
    </div>
</template>

<script>
    import ArBaseTableFilter from "../../../components/table/ArBaseTableFilter";
    import TwoParts from "../../_components/TwoParts";
    import {CalendarOutlined, SearchOutlined, SyncOutlined, AccountBookOutlined} from "@ant-design/icons-vue";
    import workCalendar from "./workCalendar";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import api_sys_department_relation from "../../../assets/api/userDepartRelation";
    import api_sys_department from "../../../assets/api/department";
    import processEmployee from "../../../assets/api/processEmployee";
    import userInfo from "../../../assets/api/userInfo";
    import memberWorkCalendar from "./memberWorkCalendar";
    import memberMonthMoney from "./memberMonthMoney";

    export default {
        name: "workerProcessShowerAll",
        components: {
            ArBaseTableFilter, TwoParts, SearchOutlined, SyncOutlined,
            workCalendar, CalendarOutlined, memberWorkCalendar, memberMonthMoney,
            AccountBookOutlined
        },
        data() {
            return {
                argument: {
                    dataSource: null,//提供table的DataSource
                    tableColumns: [
                        new Column("序号", "_index", ColumnType.Index).setTableView(60),
                        new Column("姓名", "userName", ColumnType.String).setTableView(100),
                        new Column("操作", "actions", "actions").setTableView(100),
                    ], //提供table的字-段集 this.table.getTableColumns()
                    loading: false,//table的loading状态
                },
                pageSize: 15,
                departments: [],
                defaultQuery: {
                    departmentId: null,
                    disableState: "1",
                    loginState: "0",
                    haveLevel: "0"
                },
                userId: null,
                compType: "",
                excelParams: {
                    depId: '',
                    yearMonth: ''
                }
            }
        },
        methods: {
            init() {
                this.userId = null;
                let data = {disableState: "1", loginState: "0"};
                this.defaultQuery.departmentId = null;
                this.argument.loading = true;
                this.argument.tableColumns = [
                    new Column("序号", "_index", ColumnType.Index).setTableView(60),
                    new Column("姓名", "name", ColumnType.String).setTableView(100),
                    new Column("操作", "actions", "actions").setTableView(100),
                ];
                userInfo.queryAll.requestPOSTUrlParam(this, data, res => {
                    this.argument.loading = false;
                    if (res.data.flag && res.data.data.length > 0) {
                        res.data.data = res.data.data.filter(item => item.id !== "2"); //删除超级管理员账号
                        this.argument.dataSource = res.data.data;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            refresh() {
                this.init()
            },
            selectDepartment() {
                let data = {};
                this.userId = null;
                Object.assign(data, this.defaultQuery);
                this.argument.loading = true;
                this.argument.tableColumns = [
                    new Column("序号", "_index", ColumnType.Index).setTableView(60),
                    new Column("姓名", "userName", ColumnType.String).setTableView(100),
                    new Column("操作", "actions", "actions").setTableView(100),
                ];
                api_sys_department_relation.queryAll.requestPOSTUrlParam(this, data, res => {
                    this.argument.loading = false;
                    if (res.data.flag && res.data.data.length > 0) {
                        this.argument.dataSource = res.data.data;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            workArrange(record) {
                this.userId = record.userId ? record.userId : record.id;
                this.compType = "workCalendar"
            },
            workDiary(record) {
                this.userId = record.userId ? record.userId : record.id;
                this.compType = "memberWorkCalendar"
            },
            moneyRecord(record) {
                this.userId = record.userId ? record.userId : record.id;
                this.compType = "memberMonthMoney"
            },
            changeMonth(date, dateString){
                this.excelParams.yearMonth=dateString
            },
            exoprtExcel() {
                this.excelParams.depId = this.defaultQuery.departmentId
                if (!this.excelParams.depId) {
                    this.$message.error('请选择部门')
                    return
                }
                if (!this.excelParams.yearMonth) {
                    this.$message.error('请选择导出月份')
                    return
                }
                let departmentName=this.departments.find(el=>el.id==this.excelParams.depId).name
                let fileName=`${departmentName}${this.excelParams.yearMonth.split('-')[0]}年${this.excelParams.yearMonth.split('-')[1]}月员工的任务工序.xls`
                processEmployee.processExcel.fileDownloadPost(this, this.excelParams,fileName );

            }
        },
        created() {
            api_sys_department.queryAll.requestPOST(this, {}, res => {
                if (res.data.flag) {
                    this.departments = res.data.data.filter(item => item.departType === "0");//生产部门);
                } else {
                    this.departments = []
                }
            });
            this.init()
        }
    }
</script>

<style scoped>

</style>
