/** 
* 自动生成文件，请勿修改 
* @name: 系统:用户-角色关系管理模块
* @description: 用户-角色关系管理-增删改查
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const userRoleRelation = { 
	/** 1 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/userRoleRelation/delete.do"), 
	/** 2 summary:保存/修改-批量	method:post */ 
	batchSaveOrUpd : new APIObject("/api/userRoleRelation/batchSaveOrUpd.do"), 
	/** 3 summary:查询-全部不分页(根据userId,roleId)	method:post */ 
	queryAll : new APIObject("/api/userRoleRelation/queryAll.do"), 
	/** 4 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/userRoleRelation/batchDelete.do"), 
	/** 5 summary:用户绑定角色-批量	method:post */ 
	batchBinding : new APIObject("/api/userRoleRelation/batchBinding.do"), 
	/** 6 summary:保存/修改-单条	method:post */ 
	saveOrUpd : new APIObject("/api/userRoleRelation/saveOrUpd.do"), 
	/** 7 summary:查询-分页(根据userId,roleId)	method:post */ 
	queryPage : new APIObject("/api/userRoleRelation/queryPage.do"), 
	/** 8 summary:查询-单条(根据主键)	method:post */ 
	queryOne : new APIObject("/api/userRoleRelation/queryOne.do"), 
};
export default userRoleRelation;