/** 
* 自动生成文件，请勿修改 
* @name: 资产:固定资产类别模块
* @description: 固定资产类别======================增删改查
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:5条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const fixedCategory = { 
	/** 1 summary:保存/修改-单条	method:post */ 
	saveOrUpd : new APIObject("/api/fixedCategory/saveOrUpd.do"), 
	/** 2 summary:查询-单条数据	method:post */ 
	queryOne : new APIObject("/api/fixedCategory/queryOne.do"), 
	/** 3 summary:查询-所有	method:post */ 
	queryAll : new APIObject("/api/fixedCategory/queryAll.do"), 
	/** 4 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/fixedCategory/delete.do"), 
	/** 5 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/fixedCategory/batchDelete.do"), 
};
export default fixedCategory;