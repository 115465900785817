/** 
* 自动生成文件，请勿修改 
* @name: 财务:公司各银行卡初始余额模块
* @description: 公司各银行卡初始余额模块-增删改查
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:6条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const bankBalance = { 
	/** 1 summary:根据id查询	method:post */ 
	queryOne : new APIObject("/api/bankBalance/queryOne.do"), 
	/** 2 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/bankBalance/queryPage.do"), 
	/** 3 summary:查询-不分页（全部）	method:post */ 
	queryAll : new APIObject("/api/bankBalance/queryAll.do"), 
	/** 4 summary:新增/修改-单条	method:post */ 
	saveOrUpdate : new APIObject("/api/bankBalance/saveOrUpdate.do"), 
	/** 5 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/bankBalance/delete.do"), 
	/** 6 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/bankBalance/batchDelete.do"), 
};
export default bankBalance;