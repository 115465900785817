/** 
* 自动生成文件，请勿修改 
* @name: 项目:质检工序管理模块
* @description: 项目-质检工序管理模块-增删改查(二期修改，增加字段)
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const qcProcess = { 
	/** 1 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/qcProcess/queryPage.do"), 
	/** 2 summary:保存/修改-批量	method:post */ 
	batchSaveOrUpd : new APIObject("/api/qcProcess/batchSaveOrUpd.do"), 
	/** 3 summary:查询-单条数据	method:post */ 
	queryOne : new APIObject("/api/qcProcess/queryOne.do"), 
	/** 4 summary:查询-所有数据	method:post */ 
	queryAll : new APIObject("/api/qcProcess/queryAll.do"), 
	/** 5 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/qcProcess/delete.do"), 
	/** 6 summary:保存/修改-单个质检工序	method:post */ 
	saveOrUpd : new APIObject("/api/qcProcess/saveOrUpd.do"), 
	/** 7 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/qcProcess/batchDelete.do"), 
};
export default qcProcess;