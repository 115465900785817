/** 
* 自动生成文件，请勿修改 
* @name: 系统:菜单操作码管理模块
* @description: 菜单操作码管理模块-增删改查
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:6条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const menuOperation = { 
	/** 1 summary:保存-单条菜单操作码	method:post */ 
	save : new APIObject("/api/menuOperation/save.do"), 
	/** 2 summary:查询-查询单条操作	method:post */ 
	queryOperationId : new APIObject("/api/menuOperation/queryOperationId.do"), 
	/** 3 summary:更新-单条菜单操作码对象	method:post */ 
	update : new APIObject("/api/menuOperation/update.do"), 
	/** 4 summary:删除-单条菜单操作码	method:post */ 
	delete : new APIObject("/api/menuOperation/delete.do"), 
	/** 5 summary:删除-批量菜单操作码	method:post */ 
	batchDelete : new APIObject("/api/menuOperation/batchDelete.do"), 
	/** 6 summary:查询-根据菜单id查询操作列表	method:post */ 
	queryOperation : new APIObject("/api/menuOperation/queryOperation.do"), 
};
export default menuOperation;