/** 
* 自动生成文件，请勿修改 
* @name: 支出:其他支出申请审批流水记录
* @description: 其他支出申请审批流水记录（四期新增（董灿提出））
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const otherPayApproval = { 
	/** 1 summary:批量删除	method:post */ 
	batchDelete : new APIObject("/api/otherPayApproval/batchDelete.do"), 
	/** 2 summary:批量保存/修改	method:post */ 
	batchSaveOrUpd : new APIObject("/api/otherPayApproval/batchSaveOrUpd.do"), 
	/** 3 summary:审核支出申请	method:post */ 
	approvalApply : new APIObject("/api/otherPayApproval/approvalApply.do"), 
	/** 4 summary:查询所有（可根据申请id查询该申请的审核流水状态）	method:post */ 
	queryAll : new APIObject("/api/otherPayApproval/queryAll.do"), 
	/** 5 summary:单条数据查询	method:post */ 
	queryOne : new APIObject("/api/otherPayApproval/queryOne.do"), 
	/** 6 summary:单条删除	method:post */ 
	delete : new APIObject("/api/otherPayApproval/delete.do"), 
	/** 7 summary:保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/otherPayApproval/saveOrUpd.do"), 
	/** 8 summary:分页查询（可根据申请id查询该申请的审核流水状态）	method:post */ 
	queryPage : new APIObject("/api/otherPayApproval/queryPage.do"), 
};
export default otherPayApproval;