/** 
* 自动生成文件，请勿修改 
* @name: 系统:公司系统管理模块
* @description: 公司系统管理
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:6条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const system = { 
	/** 1 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/system/batchDelete.do"), 
	/** 2 summary:查询-单条	method:post */ 
	queryOne : new APIObject("/api/system/queryOne.do"), 
	/** 3 summary:查询-不分页（全部）	method:post */ 
	queryAll : new APIObject("/api/system/queryAll.do"), 
	/** 4 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/system/queryPage.do"), 
	/** 5 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/system/delete.do"), 
	/** 6 summary:新增/编辑-单条	method:post */ 
	saveOrUpd : new APIObject("/api/system/saveOrUpd.do"), 
};
export default system;