/** 
* 自动生成文件，请勿修改 
* @name: 项目:工序-员工管理模块
* @description: 工序-员工管理模块-------修改了（带出工序下每个月完成产值）
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const processEmployee = { 
	/** 1 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/processEmployee/batchDelete.do"), 
	/** 2 summary:查询-不分页	method:post */ 
	queryAll : new APIObject("/api/processEmployee/queryAll.do"), 
	/** 3 summary:保存/修改-批量	method:post */ 
	batchSaveOrUpd : new APIObject("/api/processEmployee/batchSaveOrUpd.do"), 
	/** 4 summary:查询-单条数据	method:post */ 
	queryOne : new APIObject("/api/processEmployee/queryOne.do"), 
	/** 5 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/processEmployee/queryPage.do"), 
	/** 6 summary:返回系统时间(用于填写工序完成时自动赋值完成日期)	method:post */ 
	returnDate : new APIObject("/api/processEmployee/returnDate.do"), 
	/** 7 summary:保存/修改-单个	method:post */ 
	saveOrUpd : new APIObject("/api/processEmployee/saveOrUpd.do"), 
	/** 8 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/processEmployee/delete.do"), 
	/** 9 summary:统计某部门、某月份yyyy-MM 员工当月的头上的任务工序	method:post */ 
	processExcel : new APIObject("/api/processEmployee/processExcel.do"), 
};
export default processEmployee;