/** 
* 自动生成文件，请勿修改 
* @name: 项目:收款情况记录模块
* @description: 项目-收款情况记录-增删改查（二期修改，增加发票id、付款方、收款方）
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:10条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const collectionInfo = { 
	/** 1 summary:新增-单条	method:post */ 
	save : new APIObject("/api/collectionInfo/save.do"), 
	/** 2 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/collectionInfo/batchDelete.do"), 
	/** 3 summary:查询-分页	method:post */ 
	queryPageByObj : new APIObject("/api/collectionInfo/queryPageByObj.do"), 
	/** 4 summary:查询-单条	method:post */ 
	queryOneById : new APIObject("/api/collectionInfo/queryOneById.do"), 
	/** 5 summary:修改-单条	method:post */ 
	update : new APIObject("/api/collectionInfo/update.do"), 
	/** 6 summary:查询-不分页(可根据项目名称模糊查询)	method:post */ 
	queryAll : new APIObject("/api/collectionInfo/queryAll.do"), 
	/** 7 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/collectionInfo/delete.do"), 
	/** 8 summary:保存/修改-批量	method:post */ 
	batchSaveOrUpd : new APIObject("/api/collectionInfo/batchSaveOrUpd.do"), 
	/** 9 summary:查询-分页(可根据项目名称模糊查询)	method:post */ 
	queryPage : new APIObject("/api/collectionInfo/queryPage.do"), 
	/** 10 summary:查询-不分页(可根据项目名称模糊查询)	method:post */ 
	queryAllByObj : new APIObject("/api/collectionInfo/queryAllByObj.do"), 
};
export default collectionInfo;