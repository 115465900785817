/** 
* 自动生成文件，请勿修改 
* @name: 项目:任务/工序延期申请
* @description: 项目-任务/工序延期申请-增删改查（三期新增）
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:14条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const progressApply = { 
	/** 1 summary:查询-不分页(部门负责人用)	method:post */ 
	allDepartLeader : new APIObject("/api/progressApply/allDepartLeader.do"), 
	/** 2 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/progressApply/batchDelete.do"), 
	/** 3 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/progressApply/queryPage.do"), 
	/** 4 summary:审核-单条	method:post */ 
	approve : new APIObject("/api/progressApply/approve.do"), 
	/** 5 summary:查询-不分页(质检负责人用)	method:post */ 
	allGroupLeader : new APIObject("/api/progressApply/allGroupLeader.do"), 
	/** 6 summary:查询-分页(项目负责人用（任何人都可能是项目负责人）)	method:post */ 
	pageItemLeader : new APIObject("/api/progressApply/pageItemLeader.do"), 
	/** 7 summary:新增-单条	method:post */ 
	save : new APIObject("/api/progressApply/save.do"), 
	/** 8 summary:查询-不分页(项目负责人用（任何人都可能是项目负责人）)	method:post */ 
	allItemLeader : new APIObject("/api/progressApply/allItemLeader.do"), 
	/** 9 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/progressApply/delete.do"), 
	/** 10 summary:查询-分页(质检负责人用)	method:post */ 
	pageGroupLeader : new APIObject("/api/progressApply/pageGroupLeader.do"), 
	/** 11 summary:查询-不分页	method:post */ 
	queryAll : new APIObject("/api/progressApply/queryAll.do"), 
	/** 12 summary:查询-分页(部门负责人用)	method:post */ 
	pageDepartLeader : new APIObject("/api/progressApply/pageDepartLeader.do"), 
	/** 13 summary:查询-单条	method:post */ 
	queryOne : new APIObject("/api/progressApply/queryOne.do"), 
	/** 14 summary:修改-单条	method:post */ 
	update : new APIObject("/api/progressApply/update.do"), 
};
export default progressApply;