/** 
* 自动生成文件，请勿修改 
* @name: 考核:员工月度考核维度得分表
* @description: 员工月度考核维度得分表（三期新增）
* @create: 2024/6/28 10:59:48
* @swagger: 2.0
* 合计:14条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const assessmentUser = { 
	/** 1 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/assessmentUser/batchDelete.do"), 
	/** 2 summary:修改：多条	method:post */ 
	batchUpd : new APIObject("/api/assessmentUser/batchUpd.do"), 
	/** 3 summary:同意/不同意-多条id,逗号分隔	method:post */ 
	agree : new APIObject("/api/assessmentUser/agree.do"), 
	/** 4 summary:查询(得到某用户某年月的考核总得分)	method:post */ 
	queryScore : new APIObject("/api/assessmentUser/queryScore.do"), 
	/** 5 summary:查询-单条(根据主键)	method:post */ 
	queryOne : new APIObject("/api/assessmentUser/queryOne.do"), 
	/** 6 summary:查询-（需要自己打分的）当前登录用户为评分人	method:post */ 
	queryByJudgeUser : new APIObject("/api/assessmentUser/queryByJudgeUser.do"), 
	/** 7 summary:修改：单条	method:post */ 
	update : new APIObject("/api/assessmentUser/update.do"), 
	/** 8 summary:查询-全部不分页	method:post */ 
	queryAll : new APIObject("/api/assessmentUser/queryAll.do"), 
	/** 9 summary:指定打分人：（针对试用期员工考核中同事评分（转正表述）的考核维度）	method:post */ 
	appoint : new APIObject("/api/assessmentUser/appoint.do"), 
	/** 10 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/assessmentUser/delete.do"), 
	/** 11 summary:根据userId（部门负责人） ,月度，得到该用户（部门负责人）管理的部门员工总产值	method:post */ 
	getDepSumChanZhi : new APIObject("/api/assessmentUser/getDepSumChanZhi.do"), 
	/** 12 summary:同意/不同意-当月考核表的所有打分项都同意/不同意	method:post */ 
	agreeAll : new APIObject("/api/assessmentUser/agreeAll.do"), 
	/** 13 summary:查询-当前登录用户（被考核人自己的）	method:post */ 
	queryAllCurrentUser : new APIObject("/api/assessmentUser/queryAllCurrentUser.do"), 
	/** 14 summary:生成员工考核维度表	method:post */ 
	generate : new APIObject("/api/assessmentUser/generate.do"), 
};
export default assessmentUser;