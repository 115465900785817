/** 
* 自动生成文件，请勿修改 
* @name: 报销:报账单流水模块
* @description: 报销-报账单流水模块-增删改查
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const recordFlow = { 
	/** 1 summary:报账单流水-流水导出excel	method:get */ 
	exportExcelByRecord : new APIObject("/api/recordFlow/exportExcelByRecord.do"), 
	/** 2 summary:报账单流水-单条删除	method:post */ 
	delete : new APIObject("/api/recordFlow/delete.do"), 
	/** 3 summary:报账单流水-批量删除	method:post */ 
	batchDelete : new APIObject("/api/recordFlow/batchDelete.do"), 
	/** 4 summary:报账单流水-批量保存/修改	method:post */ 
	batchSaveOrUpd : new APIObject("/api/recordFlow/batchSaveOrUpd.do"), 
	/** 5 summary:报账单流水-所有数据查询	method:post */ 
	queryAll : new APIObject("/api/recordFlow/queryAll.do"), 
	/** 6 summary:报账单流水-分页查询	method:post */ 
	queryPage : new APIObject("/api/recordFlow/queryPage.do"), 
	/** 7 summary:报账单流水-保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/recordFlow/saveOrUpd.do"), 
	/** 8 summary:报账单流水-单条数据查询	method:post */ 
	queryOne : new APIObject("/api/recordFlow/queryOne.do"), 
};
export default recordFlow;