<!--
* @program: TYH_office_automation 
* @author: ly
* @component:personalQCTask 
* @description: 
* @create: 2021-07-09 11:06
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button  @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon><SyncOutlined /></template>
                </a-button>
                <span  style="margin-left: 10px">完成情况：</span>
                <a-radio-group  @change="radioChange" class="search-item" v-model:value="defaultOptions.state">
                    <a-radio value="3">未完成</a-radio>
                    <a-radio value="0">按时完成</a-radio>
                    <a-radio value="1">提前完成</a-radio>
                    <a-radio value="2">延期完成</a-radio>
                </a-radio-group>
            </div>
            <TwoParts ref="TwoParts" :enable-change="false" :trans="[50,30]" >
                <template #left>
                    <ArBaseTableFilter :argument="argument" :view-model="true" :page-size="pageSize">
                        <template #action="{record}">
                            <div class="action-column">
                                <a-button @click="showDetail(record)" class="action-button" type="link">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
                                <a-button v-if="!record.state" @click="editDetail(record)" class="action-button" type="link">
                                    <FormOutlined class="pointer"/>
                                </a-button>
                            </div>
                        </template>
                    </ArBaseTableFilter>
                </template>
                <template #right>
                    <div v-if="currentRecord">
                        <a-page-header style="padding: 0; border: 1px solid rgb(235, 237, 240)">
                            <template #title>
                                <div style="display: flex;">
                                    <div style="margin: 20px">质检工作</div>
                                    <div style="margin:10px 20px ;font-size: 14px;">
                                        <div v-if="!currentRecord.state">预计完成时间：{{new Date(currentRecord.endDate).toLocaleDateString()}}，若在完成时间之后提交完成，则会导致<span style="color: red">【延期完成】。<br/>请在提交报告之后点击【工作完成】</span></div>
                                        <FcForm style="width: 400px" v-else :view-model="true" :form-data="currentRecord" :form-columns="formColumns"/>
                                    </div>
                                </div>
                            </template>
                        </a-page-header>
                        <a-button @click="workDelayApply" >
                            <template #icon><FallOutlined  class="pointer"/></template>任务延期申请
                        </a-button>
                        <a-button v-if="formArg.formData.id && !currentRecord.state" @click="finishWork" >
                            <template #icon><CheckOutlined  class="pointer"/></template>工作完成
                        </a-button>
                        <ArForm :argument="formArg" :view-model="viewModel">
                            <template #action="{record}">
                                <a-button type="primary" @click="saveReport(record)">
                                    提交
                                </a-button>
                            </template>
                        </ArForm>
                    </div>
                    <div v-else>
                        <welcome2 />
                    </div>
                </template>
            </TwoParts>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroy-on-close
             :footer="null"
             :width="500"
             title="提交完成情况">
        <div>以提交完成的那一天作为任务的完成日期</div>
        <span>完成情况说明:</span><a-textarea v-model:value="describe" :auto-size="{ minRows: 2, maxRows: 6 }"/>
        <a-button type="primary" @click="saveData">
            提交
        </a-button>
    </a-modal>
    <a-modal v-model:visible="visible2"
             destroy-on-close
             :footer="null"
             :maskClosable="viewModel"
             :width="850"
             title="任务延期详情">
        <delayApply :record="currentRecord" type="3" />
    </a-modal>
</template>

<script>
    import personal_quality_check_task from "../../../assets/tables/personal_quality_check_task";
    import api_item_task_process_employee from "../../../assets/api/processEmployee";
    import item_qc_report_process from "../../../assets/tables/item_qc_report_process";
    import api_item_qc_report_process from "../../../assets/api/qcReportProcess";
    import ArBaseTableFilter from "../../../components/table/ArBaseTableFilter";
    import TwoParts from "../../_components/TwoParts";
    import welcome2 from "../../welcome2";
    import ArForm from "../../../components/form/ArForm";
    import FcForm from "../../../components/form/FcForm";
    import {SyncOutlined,FallOutlined,CheckOutlined,FormOutlined,SearchOutlined} from "@ant-design/icons-vue"
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import delayApply from "../../projectManager/projectDelayManager/delayApply";
    import workSituation from "../../../assets/api/workSituation";
    import item_work_situation from "../../../assets/tables/item_work_situation";
    import moment from "moment"
    export default {
        name: "personalQCTask",
        components:{
            ArBaseTableFilter,TwoParts,welcome2,ArForm,FcForm,delayApply,
            SyncOutlined,FallOutlined,CheckOutlined,FormOutlined,SearchOutlined
        },
        data(){
            return{
                viewModel:false,
                table:new personal_quality_check_task(),
                table2:new item_qc_report_process(),
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                formArg:{
                    formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                    formData:null,//提供Form的formData
                },
                defaultOptions:{//当前页面的查询默认条件
                    userId:this.$store.getters.userInfo.id,
                    processType:"2", //质检工序
                    state:'3'
                },
                searchOptions:[
                    {value:"queryText",label:"关键字"}
                ],
                searchData:null,
                currentRecord:null,
                pageSize:15,
                formColumns:[
                    new Column("实际完成时间","completeDate",ColumnType.Date,false),
                    new Column("完成状态","state",ColumnType.Enum,false)
                        .setEnum(["0","1","2"],["按时完成","提前完成","延期完成"],["blue","green","red"]),
                    new Column("完成情况说明","describe",ColumnType.String,true),
                ],
                visible:false,
                visible2:false,
                describe:null
            }
        },
        methods:{
            init(){
                this.currentRecord = null;
                let data = {};
                Object.assign(data,this.defaultOptions);
                this.argument.loading = true;
                api_item_task_process_employee.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                    this.argument.loading = false;
                })
            },
            refresh(){
                this.init()
            },
            radioChange(){
                this.currentRecord = null;
                let data = {};
                Object.assign(data,this.searchData);
                Object.assign(data,this.defaultOptions);
                this.argument.loading = true;
                api_item_task_process_employee.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                    this.argument.loading = false;
                });
            },
            showDetail(record){
                this.currentRecord = record;
                this.viewModel = true;
                this.reportShow()
            },
            editDetail(record){
                this.currentRecord = record;
                this.viewModel = false;
                this.reportShow()
            },
            reportShow(){
                let data={
                    processId:this.currentRecord.processId
                };
                api_item_qc_report_process.queryAll.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.formArg.formData = res.data.data[0];
                    }
                    else{
                        this.formArg.formData = this.table2.getFormData();
                    }
                })
            },
            saveReport(record){

                record.processId = this.currentRecord.processId;
                api_item_qc_report_process.saveOrUpd.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.reportShow();

                        let time = parseInt(localStorage.getItem("time"));
                        let date = new moment(time);
                        let formData = new item_work_situation().getFormData();
                        formData.processEmployeeId = this.currentRecord.id;
                        formData.workDate = date.format("YYYY-MM-DD");
                        formData.workRatio = 100;
                        formData.workContent = "完成质检工作";
                        workSituation.saveOrUpd.requestPOST(this,formData,res=>{
                            // if(res.data.flag){
                            //     this.$message.success(res.data.msg);
                            // }
                            // else{
                            //     this.$message.error(res.data.msg);
                            // }
                        })
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            finishWork(){
                this.visible = true;
            },
            saveData(){
                let time = localStorage.getItem("time");
                this.currentRecord.completeDate = parseInt(time);
                this.currentRecord.describe = this.describe;
                api_item_task_process_employee.saveOrUpd.requestPOST(this,this.currentRecord,res=>{
                    if(res.data.flag){
                        this.init();
                        this.visible = false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            workDelayApply(){
                this.visible2 = true;
            }
        },
        created() {
            this.argument.tableColumns = this.table.getTableColumns();
            this.formArg.formColumns = this.table2.getFormColumns();
            this.formArg.formData = this.table2.getFormData();
            this.init()
        }
    }
</script>

<style scoped>

</style>