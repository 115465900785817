/** 
* 自动生成文件，请勿修改 
* @name: 考核：部门年度目标产值配置
* @description: 部门年度目标产值配置（三期新增）
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const depYearKpi = { 
	/** 1 summary:所有数据查询	method:post */ 
	queryAll : new APIObject("/api/depYearKpi/queryAll.do"), 
	/** 2 summary:批量保存/修改	method:post */ 
	batchSaveOrUpd : new APIObject("/api/depYearKpi/batchSaveOrUpd.do"), 
	/** 3 summary:单条数据查询	method:post */ 
	queryOne : new APIObject("/api/depYearKpi/queryOne.do"), 
	/** 4 summary:查询部门年度目标产值及其下员工每月产值详情	method:post */ 
	queryByDepYear : new APIObject("/api/depYearKpi/queryByDepYear.do"), 
	/** 5 summary:保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/depYearKpi/saveOrUpd.do"), 
	/** 6 summary:单条删除	method:post */ 
	delete : new APIObject("/api/depYearKpi/delete.do"), 
	/** 7 summary:批量删除	method:post */ 
	batchDelete : new APIObject("/api/depYearKpi/batchDelete.do"), 
	/** 8 summary:分页查询	method:post */ 
	queryPage : new APIObject("/api/depYearKpi/queryPage.do"), 
};
export default depYearKpi;