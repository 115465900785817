
/**
 * @program: TYH_office_automation
 * @author: ly
 * @description: device_my
 * @create: 2021-06-22 16:31
 **/

import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";
export default class device_my  extends Table{
    constructor() {
        super();
        this.name = "device_my";
        this.CNName = "我的设备";
        this.columnArray = [
            new Column("设备名称","deviceName",ColumnType.String,true).setTableView(),
            new Column("申请人","userName",ColumnType.String,true).setTableView(),
            new Column("申领类型","applyType",ColumnType.Enum).setDefaultValue("1")
                .setEnum(["1","2"],["财务处申领","转借"],["green","cyan"]),
            new Column("申请理由","purposeDescri",ColumnType.String,true).setTableView(),
            new Column("设备编码类别","deviceCodeName",ColumnType.String,true).setTableView()
                .setEnum(["P/N","S/N","MAC","其他"],["P/N","S/N","MAC","其他"],["pink","blue","cyan","red"]),
            new Column("设备编码","deviceCode",ColumnType.String,true).setTableView(),
            new Column("设备状态","useState",ColumnType.String,true).setTableView()
                .setEnum(["1","0"],["已归还","未归还"],["green","purple"]),
            //new Column("设备状态","state",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions").setTableView(80)];
    }
}