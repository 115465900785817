/** 
* 自动生成文件，请勿修改 
* @name: 系统:部门管理模块
* @description: 部门管理模块-增加部门余额
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const department = { 
	/** 1 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/department/queryPage.do"), 
	/** 2 summary:新增部门-单条	method:post */ 
	save : new APIObject("/api/department/save.do"), 
	/** 3 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/department/batchDelete.do"), 
	/** 4 summary:查询-不分页（用户所属的全部部门）	method:post */ 
	queryByUser : new APIObject("/api/department/queryByUser.do"), 
	/** 5 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/department/delete.do"), 
	/** 6 summary:查询-不分页（全部）	method:post */ 
	queryAll : new APIObject("/api/department/queryAll.do"), 
	/** 7 summary:查询-获取所有部门树结构	method:post */ 
	queryTree : new APIObject("/api/department/queryTree.do"), 
	/** 8 summary:修改-单条	method:post */ 
	update : new APIObject("/api/department/update.do"), 
	/** 9 summary:查询-单条	method:post */ 
	queryInfoId : new APIObject("/api/department/queryInfoId.do"), 
};
export default department;