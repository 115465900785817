/** 
* 自动生成文件，请勿修改 
* @name: 用章：用章申请管理
* @description: 用章申请管理-增删改查
* @create: 2024/6/28 10:59:48
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const sealApply = { 
	/** 1 summary:删除-单条（未被审核时才能删除）	method:post */ 
	delete : new APIObject("/api/sealApply/delete.do"), 
	/** 2 summary:查询-所有数据	method:post */ 
	queryAll : new APIObject("/api/sealApply/queryAll.do"), 
	/** 3 summary:查询需要我审批的-分页	method:post */ 
	pageMyApproval : new APIObject("/api/sealApply/pageMyApproval.do"), 
	/** 4 summary:查询-单条	method:post */ 
	queryOne : new APIObject("/api/sealApply/queryOne.do"), 
	/** 5 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/sealApply/batchDelete.do"), 
	/** 6 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/sealApply/queryPage.do"), 
	/** 7 summary:新增/修改-单条（用章申请使用）	method:post */ 
	saveOrUpd : new APIObject("/api/sealApply/saveOrUpd.do"), 
	/** 8 summary:查询需要我审批的-不分页	method:post */ 
	allMyApproval : new APIObject("/api/sealApply/allMyApproval.do"), 
};
export default sealApply;