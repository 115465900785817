import Table from "./parts/table";
import Column from "./parts/column";
import ColumnType from "./parts/column_type";

export default class user_info extends Table{
    constructor(name,CNName) {
        super(name,CNName);
        this.name = "user_info";
        this.CNName = "用户信息表";
        this.columnArray = [
            new Column("姓名","name",ColumnType.String,false).setTableView(120).setPosition("left"),
            new Column("状态","loginState",ColumnType.Enum,false)
                .setEnum(["0","1","2"],["正式员工","试用期","实习生"],["cyan","green","pink"]).setTableView(100),

            new Column("籍贯","userExtra.nativePlace",ColumnType.String,true).setTableView(100),
            new Column("身份证号","userExtra.idCardNumber",ColumnType.String,true).setTableView(100)
                .setTriggerOtherTableColumn(null,(formData,cols)=>{
                    if(formData.userExtra && formData.userExtra.idCardNumber.length === 18){
                        formData.userExtra.sex = getSex(formData.userExtra.idCardNumber);
                        let birthday = getBirth(formData.userExtra.idCardNumber);
                        formData.userExtra.birthday = birthday;
                        formData.userExtra.age = getAge(birthday);
                    }
                },(formData,cols)=>{
                    if(formData.userExtra && formData.userExtra.idCardNumber.length === 18){
                        formData.userExtra.sex = getSex(formData.userExtra.idCardNumber);
                        let birthday = getBirth(formData.userExtra.idCardNumber);
                        formData.userExtra.birthday = birthday;
                        formData.userExtra.age = getAge(birthday);
                    }
                }),
            new Column("性别","userExtra.sex",ColumnType.String).setTableView(80),
            new Column("生日","userExtra.birthday",ColumnType.Date),
            new Column("年龄","userExtra.age",ColumnType.Number).setTableView(80),
            new Column("现住址","userExtra.currentAddr",ColumnType.String,true),
            new Column("婚姻状态","userExtra.maritalStatus",ColumnType.Enum,true).setTableView(120)
                .setEnum(["未婚","已婚","离婚","丧偶"],["未婚","已婚","离婚","丧偶"]),
            new Column("政治面貌","userExtra.politiOutLook",ColumnType.String,true).setTableView(120),
            new Column("民族","userExtra.nation",ColumnType.String,true).setTableView(120),
            new Column("毕业院校","userExtra.graduSchool",ColumnType.String,true).setGroup(1).setTableView(120),
            new Column("毕业时间","userExtra.graduDate",ColumnType.Date,true).setGroup(1).setTableView(120),
            new Column("学历","userExtra.education",ColumnType.String,true).setGroup(1).setTableView(100),
            new Column("学位","userExtra.degree",ColumnType.String,true).setGroup(1).setTableView(120),
            new Column("专业","userExtra.major",ColumnType.String,true).setGroup(1).setTableView(100),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(120).setPosition("right")];
    }
}

function getBirth(idCard) {
    let birthday = "";
    if(idCard !== null && idCard !== ""){
        if(idCard.length === 15){
            birthday = "19"+idCard.slice(6,12);
        } else if(idCard.length === 18){
            birthday = idCard.slice(6,14);
        }
        birthday = birthday.replace(/(.{4})(.{2})/,"$1/$2/");
        //通过正则表达式来指定输出格式为:1990-01-01
    }
    return birthday;
}

function getSex(idCard) {
    let sexStr = '';
    if (parseInt(idCard.slice(-2, -1)) % 2 === 1) {
        sexStr = '男';
    }
    else {
        sexStr = '女';
    }
    return sexStr;
}

function getAge(birthday) { //1900/01/01
    let date = new Date().toLocaleDateString();
    let year = parseInt(date.split("/")[0]);
    let mouth = parseInt(date.split("/")[1]);
    let day = parseInt(date.split("/")[2]);
    if(!birthday){
        return 0
    }
    let birthYear = parseInt(birthday.split("/")[0]);
    let birthMouth = parseInt(birthday.split("/")[1]);
    let birthDay = parseInt(birthday.split("/")[2]);
    let a = mouth*30 + day;
    let b = birthMouth*30 + birthDay;
    if(a - b >0){
        return year - birthYear;
    }
    else{
        return year - birthYear - 1;
    }
}

function workingYear(startDate) { //1900/01/01
    let date = new Date().toLocaleDateString();
    let year = parseInt(date.split("/")[0]);
    let mouth = parseInt(date.split("/")[1]);
    let day = parseInt(date.split("/")[2]);
    if(!startDate){
        return 0
    }
    let nDate = new Date(startDate).toLocaleDateString();
    let birthYear = parseInt(nDate.split("/")[0]);
    let birthMouth = parseInt(nDate.split("/")[1]);
    let birthDay = parseInt(nDate.split("/")[2]);
    let a = mouth*30 + day;
    let b = birthMouth*30 + birthDay;
    if(a - b >0){
        return year - birthYear;
    }
    else{
        return year - birthYear - 1;
    }
}