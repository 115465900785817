/** 
* 自动生成文件，请勿修改 
* @name: 考核:考勤管理-月度企业微信导出的考勤统计
* @description: 月度企业微信导出的考勤统计(三期新增)
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const attendance = { 
	/** 1 summary:导出-导出考勤模板	method:get */ 
	exportExcel : new APIObject("/api/attendance/exportExcel.do"), 
	/** 2 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/attendance/batchDelete.do"), 
	/** 3 summary:导入-企业微信考勤模板	method:post */ 
	importExcel : new APIObject("/api/attendance/importExcel.do"), 
	/** 4 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/attendance/delete.do"), 
	/** 5 summary:根据id查询	method:post */ 
	queryById : new APIObject("/api/attendance/queryById.do"), 
	/** 6 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/attendance/queryPage.do"), 
	/** 7 summary:查询-不分页（全部）	method:post */ 
	queryAll : new APIObject("/api/attendance/queryAll.do"), 
	/** 8 summary:新增/修改-单条	method:post */ 
	saveOrUpdate : new APIObject("/api/attendance/saveOrUpdate.do"), 
};
export default attendance;