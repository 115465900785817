/** 
* 自动生成文件，请勿修改 
* @name: 考核:考勤扣分规则详情管理
* @description: 考勤扣分规则详情管理(三期新增)
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const ruleDetail = { 
	/** 1 summary:新增/修改-单条	method:post */ 
	saveOrUpdate : new APIObject("/api/ruleDetail/saveOrUpdate.do"), 
	/** 2 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/ruleDetail/delete.do"), 
	/** 3 summary:查询-不分页（全部）	method:post */ 
	queryAll : new APIObject("/api/ruleDetail/queryAll.do"), 
	/** 4 summary:保存/修改-批量	method:post */ 
	batchSaveOrUpd : new APIObject("/api/ruleDetail/batchSaveOrUpd.do"), 
	/** 5 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/ruleDetail/batchDelete.do"), 
	/** 6 summary:根据id查询	method:post */ 
	queryById : new APIObject("/api/ruleDetail/queryById.do"), 
	/** 7 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/ruleDetail/queryPage.do"), 
};
export default ruleDetail;