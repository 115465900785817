/** 
* 自动生成文件，请勿修改 
* @name: 项目：任务-周报管理模块
* @description: 项目：任务-周报管理模块-------2024-01-09日新增
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:12条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const weekWork = { 
	/** 1 summary:提交-单条（部门领导的操作）	method:post */ 
	leaderSubmit : new APIObject("/api/weekWork/leaderSubmit.do"), 
	/** 2 summary:查询-不分页	method:post */ 
	queryAll : new APIObject("/api/weekWork/queryAll.do"), 
	/** 3 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/weekWork/batchDelete.do"), 
	/** 4 summary:查询-单条数据	method:post */ 
	queryOne : new APIObject("/api/weekWork/queryOne.do"), 
	/** 5 summary:审核-单条（部门领导的操作）	method:post */ 
	approve : new APIObject("/api/weekWork/approve.do"), 
	/** 6 summary:取消提交-单条（部门领导的操作）	method:post */ 
	cancelLeaderSubmit : new APIObject("/api/weekWork/cancelLeaderSubmit.do"), 
	/** 7 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/weekWork/delete.do"), 
	/** 8 summary:提交-单条（填写人进行提交）	method:post */ 
	submit : new APIObject("/api/weekWork/submit.do"), 
	/** 9 summary:审阅-单条（总经办的操作）	method:post */ 
	review : new APIObject("/api/weekWork/review.do"), 
	/** 10 summary:保存/修改-单个	method:post */ 
	saveOrUpd : new APIObject("/api/weekWork/saveOrUpd.do"), 
	/** 11 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/weekWork/queryPage.do"), 
	/** 12 summary:取消提交-单条（填写人的操作）	method:post */ 
	cancelSubmit : new APIObject("/api/weekWork/cancelSubmit.do"), 
};
export default weekWork;