/** 
* 自动生成文件，请勿修改 
* @name: 项目:工作日志记录模块
* @description: 工作日志记录模块-增删改查
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const workSituation = { 
	/** 1 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/workSituation/batchDelete.do"), 
	/** 2 summary:查询-单条数据	method:post */ 
	queryOne : new APIObject("/api/workSituation/queryOne.do"), 
	/** 3 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/workSituation/delete.do"), 
	/** 4 summary:查询-不分页	method:post */ 
	queryAll : new APIObject("/api/workSituation/queryAll.do"), 
	/** 5 summary:保存/修改-批量	method:post */ 
	batchSaveOrUpd : new APIObject("/api/workSituation/batchSaveOrUpd.do"), 
	/** 6 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/workSituation/queryPage.do"), 
	/** 7 summary:保存/修改-单个	method:post */ 
	saveOrUpd : new APIObject("/api/workSituation/saveOrUpd.do"), 
};
export default workSituation;