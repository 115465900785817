/** 
* 自动生成文件，请勿修改 
* @name: 资产：设备归还申请审核流水
* @description: 资产：设备归还申请审核流水
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const assetBackApproval = { 
	/** 1 summary:分页查询	method:post */ 
	queryPage : new APIObject("/api/assetBackApproval/queryPage.do"), 
	/** 2 summary:单条删除	method:post */ 
	delete : new APIObject("/api/assetBackApproval/delete.do"), 
	/** 3 summary:审核-归还申请	method:post */ 
	audit : new APIObject("/api/assetBackApproval/audit.do"), 
	/** 4 summary:保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/assetBackApproval/saveOrUpd.do"), 
	/** 5 summary:批量删除	method:post */ 
	batchDelete : new APIObject("/api/assetBackApproval/batchDelete.do"), 
	/** 6 summary:查询所有	method:post */ 
	queryAll : new APIObject("/api/assetBackApproval/queryAll.do"), 
	/** 7 summary:单条数据查询	method:post */ 
	queryOne : new APIObject("/api/assetBackApproval/queryOne.do"), 
};
export default assetBackApproval;