/** 
* 自动生成文件，请勿修改 
* @name: 资产：设备申领申请审核流水
* @description: 资产：设备申领申请审核流水
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const assetApplyApproval = { 
	/** 1 summary:报账单审核记录流水-分页查询	method:post */ 
	queryPage : new APIObject("/api/assetApplyApproval/queryPage.do"), 
	/** 2 summary:报账单审核记录流水-批量删除	method:post */ 
	batchDelete : new APIObject("/api/assetApplyApproval/batchDelete.do"), 
	/** 3 summary:查询--单条数据	method:post */ 
	queryOne : new APIObject("/api/assetApplyApproval/queryOne.do"), 
	/** 4 summary:查询所有	method:post */ 
	queryAll : new APIObject("/api/assetApplyApproval/queryAll.do"), 
	/** 5 summary:报账单审核记录流水-单条删除	method:post */ 
	delete : new APIObject("/api/assetApplyApproval/delete.do"), 
	/** 6 summary:申领申请-审核	method:post */ 
	audit : new APIObject("/api/assetApplyApproval/audit.do"), 
	/** 7 summary:保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/assetApplyApproval/saveOrUpd.do"), 
};
export default assetApplyApproval;