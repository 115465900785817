/** 
* 自动生成文件，请勿修改 
* @name: 系统:人员管理-系统用户管理模块
* @description: 系统用户管理模块-增删改查(修改过)
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:16条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const userInfo = { 
	/** 1 summary:系统用户管理-所有数据查询	method:post */ 
	queryAll : new APIObject("/api/userInfo/queryAll.do"), 
	/** 2 summary:更改用户离职状态(管理员操作)	method:post */ 
	leaveOffice : new APIObject("/api/userInfo/leaveOffice.do"), 
	/** 3 summary:用户密码修改（个人中心）	method:post */ 
	passWordModify : new APIObject("/api/userInfo/passWordModify.do"), 
	/** 4 summary:查询-根据角色id查询该角色下的所有用户	method:post */ 
	findUserByRole : new APIObject("/api/userInfo/findUserByRole.do"), 
	/** 5 summary:系统用户管理-单条数据查询	method:post */ 
	queryOne : new APIObject("/api/userInfo/queryOne.do"), 
	/** 6 summary:系统用户管理-批量保存/修改	method:post */ 
	batchSaveOrUpd : new APIObject("/api/userInfo/batchSaveOrUpd.do"), 
	/** 7 summary:系统用户管理-保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/userInfo/saveOrUpd.do"), 
	/** 8 summary:系统用户管理-批量删除	method:post */ 
	batchDelete : new APIObject("/api/userInfo/batchDelete.do"), 
	/** 9 summary:批量用户启用/禁用	method:post */ 
	batchstateModify : new APIObject("/api/userInfo/batchstateModify.do"), 
	/** 10 summary:初始化某个用户的密码(管理员操作)	method:post */ 
	passWordInitial : new APIObject("/api/userInfo/passWordInitial.do"), 
	/** 11 summary:系统用户管理-单条删除	method:post */ 
	delete : new APIObject("/api/userInfo/delete.do"), 
	/** 12 summary:单个用户启用/禁用	method:post */ 
	stateModify : new APIObject("/api/userInfo/stateModify.do"), 
	/** 13 summary:更新头像文件路径	method:post */ 
	updHeadImg : new APIObject("/api/userInfo/updHeadImg.do"), 
	/** 14 summary:修改用户是否可以报账所有项目	method:post */ 
	allItemFlag : new APIObject("/api/userInfo/allItemFlag.do"), 
	/** 15 summary:系统用户管理-分页查询	method:post */ 
	queryPage : new APIObject("/api/userInfo/queryPage.do"), 
	/** 16 summary:查询-根据部门id查询该部门下的所有用户	method:post */ 
	findUserByDepart : new APIObject("/api/userInfo/findUserByDepart.do"), 
};
export default userInfo;