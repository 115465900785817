<!--
* @program: TYH_office_automation 
* @author: ly
* @component:UserProfile 
* @description: 简历模板
* @create: 2024-03-05 19:59
-->
<template>
    <div style="width:100%;height:100%">
        <div v-if="visible" style="border: black 1px solid">
            <div class="photo-pos">
                <a-image v-if="previewImage" :alt="previewImage" style="width: 100%; height: 100%; object-fit: cover;"  :src="previewImage" />
                <span v-else>（未上照片）</span>
            </div>
            <div class="form-title">
                <span>个人信息</span>
                <span v-if="record.fileUrl" style="color:#045bbb;cursor: pointer" @click="showPDF">（简历原件）</span>
                <span v-else>（未上传简历原件）</span>
            </div>
            <a-row class="form-row">
                <a-col class="form-col" :span="6" />
                <a-col class="form-col" :span="6">
                    <div class="form-label">姓名</div>
                    <div class="form-value">{{name}}</div>
                </a-col>
                <a-col class="form-col" :span="6">
                    <div class="form-label">性别</div>
                    <div class="form-value">{{record.sex}}</div>
                </a-col>
                <a-col class="form-col" :span="6">
                    <div class="form-label">民族</div>
                    <div class="form-value">{{record.nation}}</div>
                </a-col>
            </a-row>
            <a-row class="form-row">
                <a-col class="form-col" :span="6" />
                <a-col class="form-col" :span="6">
                    <div class="form-label">生日</div>
                    <div class="form-value">{{record.birthday}}</div>
                </a-col>
                <a-col class="form-col" :span="12">
                    <div class="form-label">身份证号</div>
                    <div class="form-value">{{record.idCardNumber}}</div>
                </a-col>
            </a-row>
            <a-row class="form-row">
                <a-col class="form-col" :span="6" />
                <a-col class="form-col" :span="6">
                    <div class="form-label">年龄</div>
                    <div class="form-value">{{record.age}}</div>
                </a-col>
                <a-col class="form-col" :span="6">
                    <div class="form-label">紧急联系人</div>
                    <div class="form-value">{{record.emergContact}}</div>
                </a-col>
                <a-col class="form-col" :span="6">
                    <div class="form-label">紧急联系电话</div>
                    <div class="form-value">{{record.emergPhone}}</div>
                </a-col>
            </a-row>
            <a-row class="form-row">
                <a-col class="form-col" :span="6" />
                <a-col class="form-col" :span="6">
                    <div class="form-label">政治面貌</div>
                    <div class="form-value">{{record.politiOutLook}}</div>
                </a-col>
                <a-col class="form-col" :span="6">
                    <div class="form-label">籍贯</div>
                    <div class="form-value">{{record.nativePlace}}</div>
                </a-col>
                <a-col class="form-col" :span="6">
                    <div class="form-label">婚姻状态</div>
                    <div class="form-value">{{record.maritalStatus}}</div>
                </a-col>
            </a-row>
            <a-row class="form-row">
                <a-col class="form-col" :span="6" />
                <a-col class="form-col" :span="18">
                    <div class="form-label">现住址</div>
                    <div class="form-value">{{record.currentAddr}}</div>
                </a-col>
            </a-row>
            <div class="form-title">学历情况</div>
            <a-row class="form-row">
                <a-col class="form-col" :span="8">
                    <div class="form-label">毕业院校</div>
                    <div class="form-value">{{record.graduSchool}}</div>
                </a-col>
                <a-col class="form-col" :span="8">
                    <div class="form-label">毕业时间</div>
                    <div class="form-value"><TimestampFormatter :timestamp="record.graduDate"/></div>
                </a-col>
                <a-col class="form-col" :span="8">
                    <div class="form-label">学历</div>
                    <div class="form-value">{{record.education}}</div>
                </a-col>
            </a-row>
            <a-row class="form-row">
                <a-col class="form-col" :span="8">
                    <div class="form-label">学位</div>
                    <div class="form-value">{{record.degree}}</div>
                </a-col>
                <a-col class="form-col" :span="8">
                    <div class="form-label">专业</div>
                    <div class="form-value">{{record.major}}</div>
                </a-col>
                <a-col class="form-col" :span="8">
                    <div class="form-label">职称资质</div>
                    <div class="form-value">{{record.titleQualify}}</div>
                </a-col>
            </a-row>
            <a-row class="form-row">
                <a-col class="form-col" :span="8">
                    <div class="form-label">职称专业</div>
                    <div class="form-value">{{record.titleProfession}}</div>
                </a-col>
                <a-col class="form-col" :span="8">
                    <div class="form-label">职称任职时间</div>
                    <div class="form-value"><TimestampFormatter :timestamp="record.titleGetDate"/></div>
                </a-col>
                <a-col class="form-col" :span="8">
                    <div class="form-label">人事档案所在地</div>
                    <div class="form-value">{{record.archivesPlace}}</div>
                </a-col>
            </a-row>
            <div class="form-title" v-if="state === '1'">入职信息</div>
            <a-row class="form-row" v-if="state === '1'">
                <a-col class="form-col" :span="6">
                    <div class="form-label">入职日期</div>
                    <div class="form-value"><TimestampFormatter :timestamp="record.inductionDate"/></div>
                </a-col>
                <a-col class="form-col" :span="6">
                    <div class="form-label">入职工龄</div>
                    <div class="form-value">{{record.workingYear}}年</div>
                </a-col>
                <a-col class="form-col" :span="6">
                    <div class="form-label">职位</div>
                    <div class="form-value">{{record.positionName}}</div>
                </a-col>
                <a-col class="form-col" :span="6">
                    <div class="form-label">岗位级别</div>
                    <div class="form-value">{{record.positionLevel}}</div>
                </a-col>
            </a-row>
            <a-row class="form-row" v-if="state === '1'">
                <a-col class="form-col" :span="6">
                    <div class="form-label">劳动合同</div>
                    <div class="form-value">{{record.laborContractFlag?"已签订":"未签订"}}</div>
                </a-col>
                <a-col class="form-col" :span="6">
                    <div class="form-label">合同编号</div>
                    <div class="form-value">{{record.contractNum}}</div>
                </a-col>
                <a-col class="form-col" :span="6">
                    <div class="form-label">签订合同时间</div>
                    <div class="form-value"><TimestampFormatter :timestamp="record.sigContractDate"/></div>
                </a-col>
                <a-col class="form-col" :span="6">
                    <div class="form-label">签订合同期限</div>
                    <div class="form-value">{{record.contractTerm}}</div>
                </a-col>
            </a-row>
            <a-row class="form-row" v-if="state === '1'">
                <a-col class="form-col" :span="8">
                    <div class="form-label">社保缴纳起始日期</div>
                    <div class="form-value"><TimestampFormatter :timestamp="record.insuranceDate"/></div>
                </a-col>
                <a-col class="form-col" :span="8">
                    <div class="form-label">公积金缴纳起始日期</div>
                    <div class="form-value"><TimestampFormatter :timestamp="record.publicFundDate"/></div>
                </a-col>
                <a-col class="form-col" :span="8">
                    <div class="form-label">试用期截止日期</div>
                    <div class="form-value"><TimestampFormatter :timestamp="record.trialExpireDate"/></div>
                </a-col>
            </a-row>
            <a-row class="form-row">
                <a-col class="form-col" :span="12">
                    <div class="form-label">任职资格证书</div>
                    <div class="form-value">{{record.qualifCertif}}</div>
                </a-col>
                <a-col class="form-col" :span="12">
                    <div class="form-label">技能证书</div>
                    <div class="form-value">{{record.certificate}}</div>
                </a-col>
            </a-row>
            <div class="form-title">工作经历</div>
            <a-row class="form-row">
                <a-col :span="24">
                    <pre class="form-value">{{record.remarks}}</pre>
                </a-col>
            </a-row>
        </div>
        <div v-else style="border: black 1px solid">
            <a-button @click="showPDF">返回</a-button>
            <embed style="width: calc(100%);height: 800px" :alt="previewPDF"  :src="previewPDF" />
        </div>
    </div>
</template>

<script>
    import TimestampFormatter from "./TimestampFormatter";
    import {GetCurrentFileUrl} from "../../../assets/utils/general";
    export default {
        name: "UserProfile",
        components:{TimestampFormatter},
        props:{
            record:{
                required:true,
                default:{}
            },
            name:{
                required:true
            },
            state:{//1在职  0离职   用于区分是否显示入职年限等其他一些入职信息
                required:true
            }
        },
        computed:{
            previewPDF(){
                return GetCurrentFileUrl(this.record.fileUrl)
            },
            previewImage(){
                return GetCurrentFileUrl(this.record.headUrl)
            }
        },
        data(){
            return{
                visible:true,
            }
        },
        methods:{
            showPDF(){
                this.visible = !this.visible;
            }
        }
    }
</script>

<style scoped>
    .form-title{
        padding: 10px;
        display: flex;
        align-items: center;
        height: 42px;
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #001A47;
        line-height: 0px;
    }
    .form-row{
        padding: 5px 10px ;
    }
    .form-col{
        width: 100%;
        display: flex;
        align-items: center;

    }
    .form-label{
        font-size: 16px;
        color: #6A7992;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: right;
    }
    .form-value{
        text-align: left;
        font-size: 16px;
        color: #001A47;
        margin-left: 15px;
        font-weight: bold;
    }
    .photo-pos{
        width: 128px;
        height: 180px;
        position: absolute;
        left: 50px;
        top:70px;
        border: #022d5c 1px solid;
    }
</style>