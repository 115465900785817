/** 
* 自动生成文件，请勿修改 
* @name: 用章:公章管理模块
* @description: 公章管理模块-增删改查
* @create: 2024/6/28 10:59:48
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const officialSeal = { 
	/** 1 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/officialSeal/queryPage.do"), 
	/** 2 summary:保存/修改-单条	method:post */ 
	saveOrUpd : new APIObject("/api/officialSeal/saveOrUpd.do"), 
	/** 3 summary:启用/停用-可单条/批量	method:post */ 
	batchModifyState : new APIObject("/api/officialSeal/batchModifyState.do"), 
	/** 4 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/officialSeal/delete.do"), 
	/** 5 summary:查询-所有数据	method:post */ 
	queryAll : new APIObject("/api/officialSeal/queryAll.do"), 
	/** 6 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/officialSeal/batchDelete.do"), 
	/** 7 summary:查询-单条	method:post */ 
	queryOne : new APIObject("/api/officialSeal/queryOne.do"), 
};
export default officialSeal;