<!--
* @program: tyh-oa 
* @author: ly
* @component:projectContract 项目合同
* @description: 
* @create: 2021-04-27 14:56
-->
<template>
    <a-button v-if="!viewModel" class="search-item" type="dashed" shape="circle" @click="addData">
        <template #icon><PlusOutlined /></template>
    </a-button>
    <a-spin :spinning="loading" tip="正在查询数据库...">
        <div style="width: 100%;height: 600px;overflow: auto">
            <div v-if="dataSource.length>0" class="center-flex" style="display: flex;flex-wrap: wrap;justify-content: center;">
                <a-card v-for="(item,idx) in dataSource" :key="idx" style="width: 400px">
                    <FcForm style="width: 350px" :form-columns="getFormColumns(item)" :form-data="item" :view-model="true" >
                        <template #action2>
                            <a-button class="action-button" type="link" @click="showDetail(item)">
                                <SearchOutlined class="pointer"/>
                            </a-button>
                            <a-button v-if="!viewModel" class="action-button" type="link" @click="editDetail(item)">
                                <EditFilled class="pointer"/>
                            </a-button>
                            <a-popconfirm
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    v-if="!viewModel"
                                    title="确定删除吗?"
                                    @confirm="deleteData(idx,item)">
                                <DeleteOutlined  style="color: red" class="pointer"/>
                            </a-popconfirm>
                        </template>
                    </FcForm>
                </a-card>
            </div>
            <div v-else style="font-size: 20px">还未添加合同</div>
            <a-divider orientation="left">中标通知书</a-divider>
            <div v-if="dataSource_zb.length>0" class="center-flex" style="display: flex;flex-wrap: wrap;justify-content: center;">
                <a-card v-for="(item,idx) in dataSource_zb" :key="idx" style="width: 400px">
                    <FcForm style="width: 350px" :form-columns="getFormColumns(item)" :form-data="item" :view-model="true" >
                        <template #action2>
                            <a-button class="action-button" type="link" @click="showDetail(item)">
                                <SearchOutlined class="pointer"/>
                            </a-button>
                            <a-popconfirm
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    v-if="!viewModel"
                                    title="确定删除吗?"
                                    @confirm="deleteData2(idx,item)">
                                <DeleteOutlined  style="color: red" class="pointer"/>
                            </a-popconfirm>
                        </template>
                    </FcForm>
                </a-card>
            </div>
        </div>
    </a-spin>

    <a-modal
            width="800px"
            :visible="modalVisible"
            :footer="null" :title="title"
            destroyOnClose
            :maskClosable="vModel"
            @cancel="modalVisible=false">
        <ArBaseForm :argument="formArg" :view-model="vModel" :span-col="true" >
            <template #action="{record}">
                <a-button type="primary" @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </ArBaseForm>
    </a-modal>
</template>

<script>
    import {PlusOutlined,SearchOutlined,EditFilled,DeleteOutlined} from "@ant-design/icons-vue"
    import item_contract_info from "../../../assets/tables/item_contract_info";
    import api_item_contract_info from "../../../assets/api/contractInfo";
    import ArBaseForm from "../../../components/form/ArBaseForm";
    import FcForm from "../../../components/form/FcForm";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import {companyColors, companyIds, companyNames} from "../../../assets/system/companies";
    import ItemDoc from "../../../assets/api/ItemDoc";
    import contractType from "../contractType";
    import {uuid} from "../../../assets/utils/general";
    export default {
        name: "projectContract",
        props:["projectId","viewModel"],
        components:{
            PlusOutlined,ArBaseForm,FcForm,
            SearchOutlined,EditFilled,DeleteOutlined
        },
        data(){
            return{
                table:new item_contract_info(),
                modalVisible:false,
                title:null,
                formArg:{
                    formColumns:null, //提供Form的字段集
                    formData:null,//提供Form的formData
                },
                loading:false,
                formColumns:null,
                dataSource:[],//提供table的DataSource
                dataSource_zb:[],//提供table的DataSource
                queryOptions:{
                    // "itemId": "string",
                    // "itemName": "string",
                    // "name": "string",
                    // "number": "string",
                    // "type": "string"
                },
                vModel:false,
            }
        },
        methods:{
            init(){
              this.formArg.formColumns = this.table.getFormColumns();
              this.formColumns = [];
                this.init_ht();
                this.init_zb();
            },
            init_ht(){
              this.dataSource = [];
              this.queryOptions={
                "itemId": this.projectId,
                "itemName": "",
                "name": "",
                "number": "",
                "type": ""
              };
              this.loading = true;
              api_item_contract_info.queryAll.requestPOST(this,this.queryOptions,res=>{
                this.loading = false;
                if(res.data.flag){
                  this.dataSource = res.data.data;
                }
                else{
                  this.dataSource = []
                }
              })
            },
            init_zb(){
              this.dataSource_zb = [];
              let docParms ={
                "itemId": this.projectId,
                "docName": "中标通知书",
                "approveState": "",
                "approveUser": "",
              };
              this.loading = true;
              ItemDoc.queryAll.requestPOST(this,docParms,res=>{
                this.loading = false;
                if(res.data.flag){
                  let info = res.data.data;
                  if(info.length > 0){
                    for(let i in info){
                      let parm = {
                        id:info[i].id,
                        type:"3",
                        describe:info[i].describe,
                        fileUrl:info[i].docFile,
                        itemId:info[i].itemId
                      }
                      this.dataSource_zb.push(parm);
                    }
                  }

                }
              })
            },
            getFormColumns(item){
                if(item.type === "1" || item.type === "4"){
                    return [
                        new Column("合同类别","type",ColumnType.Enum)
                            .setEnum(contractType.value,contractType.label,contractType.color),
                        new Column("合同名称","name",ColumnType.String),
                        new Column("合同编号","number",ColumnType.String),
                        new Column("合同金额","amount",ColumnType.Number),
                        new Column("签订人","sigUserName",ColumnType.String),
                        new Column("甲方","associatedName",ColumnType.String),
                        new Column("签订公司","sigCompany",ColumnType.String)
                            .setEnum(companyIds, companyNames,companyColors),
                        new Column("合同文件","fileUrl",ColumnType.PreViewFile)
                    ]
                }
                else if(item.type === "2" || item.type === "5"){
                    return [
                        new Column("合同类别","type",ColumnType.Enum)
                            .setEnum(contractType.value,contractType.label,contractType.color),
                        new Column("合同名称","name",ColumnType.String),
                        new Column("合同编号","number",ColumnType.String),
                        new Column("合同金额","amount",ColumnType.Number),
                        new Column("签订人","sigUserName",ColumnType.String),
                        new Column("外协单位","associatedName",ColumnType.String),
                        new Column("签订公司","sigCompany",ColumnType.String)
                            .setEnum(companyIds, companyNames,companyColors),
                        new Column("合同文件","fileUrl",ColumnType.PreViewFile)
                    ]
                }
                else if(item.type === "6"){
                    return [
                        new Column("类别","type",ColumnType.Enum)
                            .setEnum(contractType.value,contractType.label,contractType.color),
                        new Column("描述说明","describe",ColumnType.String,true),
                        new Column("中标文件","fileUrl",ColumnType.PreViewFile),
                        new Column("个人外协","associatedName",ColumnType.String)
                    ]
                }
                else if(item.type === "7"){
                    return [
                        new Column("类别","type",ColumnType.Enum)
                            .setEnum(contractType.value,contractType.label,contractType.color),
                        //new Column("甲方","associatedName",ColumnType.String),
                        new Column("合同金额","amount",ColumnType.Number,true),
                        new Column("签订人","sigUserName",ColumnType.String,true),
                        new Column("签订公司","sigCompany",ColumnType.String)
                            .setEnum(companyIds, companyNames,companyColors),
                        new Column("描述说明","describe",ColumnType.String,true),
                    ]
                }
                else{
                  return [
                    new Column("类别","type",ColumnType.Enum)
                        .setEnum(contractType.value,contractType.label,contractType.color),
                    new Column("描述说明","describe",ColumnType.String,true),
                    new Column("中标文件","fileUrl",ColumnType.PreViewFile)
                  ]
                }

            },
            showDetail(record){
                this.title="数据详情";
                this.formArg.formData = record;
                this.modalVisible = true;
                this.vModel = true;
            },
            editDetail(record){
                this.title="数据详情";
                this.formArg.formData = record;
                this.modalVisible = true;
                this.vModel = false;
            },
            deleteData(index,record){
                let data={id:record.id};
                api_item_contract_info.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.dataSource.splice(index,1);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            deleteData2(index,record){
                let data={id:record.id};
                ItemDoc.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init_zb();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(record){
              if(record.type !== '3' ) {
                  if(record.type === "7"){
                      record.name = "无需合同情况说明"
                      record.number = "无需合同情况说明" + uuid(5,10);
                      record.signState = "1"
                      record.sealFlag = "0"
                  }
                api_item_contract_info.saveOrUpd.requestPOST(this,record,res=>{
                  if(res.data.flag){
                    this.$message.success(res.data.msg);
                    this.modalVisible = false;
                    this.init_ht();
                  }
                  else{
                    this.$message.error(res.data.msg);
                  }
                });
              }else{
                let parms = {
                  docName:"中标通知书",
                  docFile:record.fileUrl,
                  id:record.id,
                  itemId:record.itemId,
                  describe:record.describe,
                  noticeMark:"1"
                }
                ItemDoc.saveOrUpd.requestPOST(this,parms,res=>{
                  if(res.data.flag){
                    this.$message.success(res.data.msg);
                    this.modalVisible = false;
                    this.init_zb();
                  }
                  else{
                    this.$message.error(res.data.msg);
                  }
                });
              }


            },
            addData(){
                this.title = "添加新合同";
                this.formArg.formData = this.table.getFormData();
                this.formArg.formData.itemId = this.projectId;
                this.modalVisible = true;
                this.vModel = false;
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>