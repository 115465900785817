<!--
* @description：报表详情
* @author:rzl
* @date:2022/8/9 11:36
-->

<template>
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" v-if="argument_xmskjl.dataSource.length > 0" >
        <template #tab>
          <div style="font-size: 14px;color: #faad14;">{{xmskjl_sum.toFixed(2)}}</div>
          <div style="font-size: 16px;">项目收款记录</div>
        </template>

        <!-- table start -->
        <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument_xmskjl" :view-model="true"  :scroll="{y:400}">

        </ArBaseTable>
        <!-- table end -->
      </a-tab-pane>
      <a-tab-pane key="2" v-if="argument_qtsr.dataSource.length > 0" >
        <template #tab>
          <div style="font-size: 14px;color: #faad14;">{{qtsr_sum.toFixed(2)}}</div>
          <div style="font-size: 16px;">其他收入</div>
        </template>
        <!-- table start -->
        <ArBaseTable ref="pstable" :page-size="pageParam.limit"  :argument="argument_qtsr" :view-model="true" :scroll="{y:400}">

        </ArBaseTable>
        <!-- table end -->
      </a-tab-pane>
      <a-tab-pane key="3" v-if="argument_qtqc.dataSource.length > 0" >
        <template #tab>
          <div style="font-size: 14px;color: #faad14;">{{qtqc_sum.toFixed(2)}}</div>
          <div style="font-size: 16px;">其他支出</div>
        </template>
        <!-- table start -->
        <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument_qtqc" :view-model="true" :scroll="{y:400}">

        </ArBaseTable>
        <!-- table end -->
      </a-tab-pane>
      <a-tab-pane key="4" v-if="argument_wxzc.dataSource.length > 0" >
        <template #tab>
          <div style="font-size: 14px;color: #faad14;">{{wxzc_sum.toFixed(2)}}</div>
          <div style="font-size: 16px;">外协支付</div>
        </template>
        <!-- table start -->
        <ArBaseTable ref="pstable" :page-size="pageParam.limit"  :argument="argument_wxzc" :view-model="true" :scroll="{y:400}">

        </ArBaseTable>
        <!-- table end -->
      </a-tab-pane>
      <a-tab-pane key="5" v-if="argument_ygbz.dataSource.length > 0" >
        <template #tab>
          <div style="font-size: 14px;color: #faad14;">{{ygbz_sum.toFixed(2)}}</div>
          <div style="font-size: 16px;">员工报账</div>
        </template>
        <!-- table start -->
        <ArBaseTable ref="pstable" :page-size="8" :argument="argument_ygbz" :view-model="true" :scroll="{y:400}">
          <template v-slot:action="{index,record}">
            <div style="justify-content: center;display: flex;">
              <a-button type="link" @click="showDetail(index,record,'ygbz')">
                <template #icon><Show title="查看"/></template>
              </a-button>
            </div>
          </template>
        </ArBaseTable>
        <!-- table end -->
      </a-tab-pane>

      <a-tab-pane key="7" v-if="argument_ht.dataSource.length > 0" >
        <template #tab>
          <div style="font-size: 14px;color: #faad14;">{{ht_sum.toFixed(2)}}</div>
          <div style="font-size: 16px;">合同</div>
        </template>
        <!-- table start -->
        <ArBaseTable ref="pstable" :page-size="pageParam.limit"  :argument="argument_ht" :view-model="true" :scroll="{y:400}">

        </ArBaseTable>
        <!-- table end -->
      </a-tab-pane>

      <a-tab-pane key="8" v-if="argument_xxfp.dataSource.length > 0" >
        <template #tab>
          <div style="font-size: 14px;color: #faad14;">{{xxfp_sum.toFixed(2)}}</div>
          <div style="font-size: 16px;">销项发票</div>
        </template>
        <!-- table start -->
        <ArBaseTable ref="pstable" :page-size="pageParam.limit"  :argument="argument_xxfp" :view-model="true" :scroll="{y:400}">

        </ArBaseTable>
        <!-- table end -->
      </a-tab-pane>

      <a-tab-pane key="9" v-if="argument_jxfp.dataSource.length > 0" >
        <template #tab>
          <div style="font-size: 14px;color: #faad14;">{{jxfp_sum.toFixed(2)}}</div>
          <div style="font-size: 16px;">进项发票</div>
        </template>
        <!-- table start -->
        <ArBaseTable ref="pstable" :page-size="pageParam.limit"  :argument="argument_jxfp" :view-model="true" :scroll="{y:400}">

        </ArBaseTable>
        <!-- table end -->
      </a-tab-pane>

      <a-tab-pane key="10" v-if="argument_gdzc.dataSource.length > 0" >
        <template #tab>
          <div style="font-size: 14px;color: #faad14;">{{gdzc_sum.toFixed(2)}}</div>
          <div style="font-size: 16px;">固定资产</div>
        </template>
        <!-- table start -->
        <ArBaseTable ref="pstable" :page-size="pageParam.limit"  :argument="argument_gdzc" :view-model="true" :scroll="{y:400}">
          <template v-slot:action="{index,record}">
            <div style="justify-content: center;display: flex;">
              <a-button type="link" @click="showDetail(index,record,'gdzc')">
                <template #icon><Show title="查看"/></template>
              </a-button>
            </div>
          </template>
        </ArBaseTable>
        <!-- table end -->
      </a-tab-pane>

      <template #tabBarExtraContent>
        <a-button type="primary" @click="downFile" style="margin-left: 10px">
          <template #icon><Download title="导出数据"/></template>导出数据
        </a-button>
      </template>
    </a-tabs>



  <a-modal v-model:visible="visible"
           destroyOnClose
           :width="1200"
           :footer="null"
           title="详情信息">
    <ArBaseTable  :download="true"  :view-model="true" :argument="formArg" :page-size="15" :scroll="{x:1100,y:400}">

    </ArBaseTable>
    <span style="font-size:20px">合计金额：{{sumMoney}}</span>
  </a-modal>
</template>

<script>
import ArBaseTable from "../../../components/table/ArBaseTable";
import Icons from "../../common/Icons";
import {dataToExcel,dataToExcel_list} from "../../../assets/utils/excelUtil"
import {timestamp} from "../../../assets/utils/dataUtil"

import recordList_item from "./config/recordList_item";
import collection_item from "./config/collection_item";
import income_item from "./config/income_item";
import otherPay_item from "./config/otherPay_item";
import payApply_item from "./config/payApply_item";
import contractList_item from "./config/contractList_item";
import deviceTempList_item from "./config/deviceTempList_item";
import invoiceIntoList_item from "./config/invoiceIntoList_item";
import invoiceRecordList_item from "./config/invoiceRecordList_item";
import deviceTemp_item from "./config/deviceTemp_item";
import depmentWater_item from "./config/depmentWater_item";

export default {
  name: "financeReportDetails",
  mixins:[Icons],
  props:['downFileName'],
  components:{
    ArBaseTable
  },
  data(){
    return{
      activeKey:'1',
      visible:false,

      pageParam:{
        page: 1,
        limit: 10,
      },
      /*** 项目收款记录 **/
      argument_xmskjl:{
        dataSource:[],//提供table的DataSource
        tableColumns:new collection_item().getTableColumns(),
      },
      xmskjl_sum:0,
      /*** 其他收入 **/
      argument_qtsr:{
        dataSource:[],//提供table的DataSource
        tableColumns:new income_item().getTableColumns(),
      },
      qtsr_sum:0,
      /*** 其他支出 **/
      argument_qtqc:{
        dataSource:[],//提供table的DataSource
        tableColumns:new otherPay_item().getTableColumns(),
      },
      qtqc_sum:0,
      /*** 外协支付 **/
      argument_wxzc:{
        dataSource:[],//提供table的DataSource
        tableColumns:new payApply_item().getTableColumns(),
      },
      wxzc_sum:0,
      /*** 员工报账 **/
      argument_ygbz:{
        dataSource:[],//提供table的DataSource
        tableColumns:new recordList_item().getTableColumns(),
      },
      ygbz_sum:0,
      argument2_ygbz:{
        dataSource:[],//提供table的DataSource
        tableColumns:new depmentWater_item().getTableColumns(),
      },

      /*** 合同 **/
      argument_ht:{
        dataSource:[],//提供table的DataSource
        tableColumns:new contractList_item().getTableColumns(),
      },
      ht_sum:0,
      /*** 销项发票 **/
      argument_xxfp:{
        dataSource:[],//提供table的DataSource
        tableColumns:new invoiceRecordList_item().getTableColumns(),
      },
      xxfp_sum:0,
      /*** 进项发票 **/
      argument_jxfp:{
        dataSource:[],//提供table的DataSource
        tableColumns:new invoiceIntoList_item().getTableColumns(),
      },
      jxfp_sum:0,
      /*** 固定资产 **/
      argument_gdzc:{
        dataSource:[],//提供table的DataSource
        tableColumns:new deviceTemp_item().getTableColumns(),
      },
      gdzc_sum:0,
      argument_gdzc2:{
        dataSource:[],//提供table的DataSource
        tableColumns:new deviceTempList_item().getTableColumns(),
      },

      formArg:{
        dataSource:[],//提供table的DataSource
        tableColumns:[],
      },
      sumMoney:0,

    }
  },
  methods:{
    /**
     *
     * @param data
     */
    setInit(data){
      /*** 项目收款记录 **/
      if(data.collectionList != null && data.collectionList.length > 0){
        data.collectionList.forEach((item,index)=>{
          this.xmskjl_sum += item.collectionAmount;
        })
        this.argument_xmskjl.dataSource = data.collectionList
      }else{
        this.xmskjl_sum = 0;
        this.argument_xmskjl.dataSource = [];
      }
      /*** 其他收入 **/
      if(data.incomeList != null && data.incomeList.length > 0){
        data.incomeList.forEach((item,index)=>{
          this.qtsr_sum += item.collectAmount;
        })
        this.argument_qtsr.dataSource = data.incomeList
      }else{
        this.qtsr_sum = 0;
        this.argument_qtsr.dataSource = [];
      }
      /*** 其他支出 **/
      if(data.otherPayList != null && data.otherPayList.length > 0){
        data.otherPayList.forEach((item,index)=>{
          this.qtqc_sum += item.payAmount;
        })
        this.argument_qtqc.dataSource = data.otherPayList
      }else{
        this.qtqc_sum = 0;
        this.argument_qtqc.dataSource = [];
      }
      /*** 外协支付 **/
      if(data.payApplyList != null && data.payApplyList.length > 0){
        data.payApplyList.forEach((item,index)=>{
          this.wxzc_sum += item.payAmount;
        })
        this.argument_wxzc.dataSource = data.payApplyList
      }else{
        this.wxzc_sum = 0;
        this.argument_wxzc.dataSource = [];
      }
      /*** 员工报账 **/
      if(data.recordList != null && data.recordList.length > 0){
        data.recordList.forEach((item,index)=>{
          this.ygbz_sum += item.applyAmount;
        })
        this.argument_ygbz.dataSource = data.recordList
      }else{
        this.ygbz_sum = 0;
        this.argument_ygbz.dataSource = [];
      }

      /*** 合同 **/
      if(data.contractList != null && data.contractList.length > 0){
        data.contractList.forEach((item,index)=>{
          this.ht_sum += item.amount;
        })
        this.argument_ht.dataSource = data.contractList
      }else{
        this.ht_sum = 0;
        this.argument_ht.dataSource = [];
      }

      /*** 销项发票 **/
      if(data.invoiceRecordList != null && data.invoiceRecordList.length > 0){
        data.invoiceRecordList.forEach((item,index)=>{
          this.xxfp_sum += item.invoiceAmount;

          item.itemName= item.itemInfo.name;
          item.partyInfoName= item.partyInfo.name;
        })
        this.argument_xxfp.dataSource = data.invoiceRecordList
      }else{
        this.xxfp_sum = 0;
        this.argument_xxfp.dataSource = [];
      }

      /*** 进项发票 **/
      if(data.invoiceIntoList != null && data.invoiceIntoList.length > 0){
        data.invoiceIntoList.forEach((item,index)=>{
          this.jxfp_sum += item.invoiceAmount;

          item.itemName= item.itemInfo.name;
          item.partyInfoName= item.partyInfo.name;
        })
        this.argument_jxfp.dataSource = data.invoiceIntoList
      }else{
        this.jxfp_sum = 0;
        this.argument_jxfp.dataSource = [];
      }

      /*** 固定资产 **/
      let deviceTempList = data.deviceTempList;
      if(deviceTempList != null && deviceTempList.length > 0){
        deviceTempList.forEach((item,index)=>{
          let fixedCategoryName = "";
          let priceSum = 0;
          if(item != null && item.length > 0){
            fixedCategoryName = item[0].fixedCategoryName;
            item.forEach((info,i)=>{
              priceSum += info.price;
            })
          }
          this.argument_gdzc.dataSource.push({
            fixedCategoryName:fixedCategoryName,
            priceSum:priceSum,
            childreList:item
          })
          this.gdzc_sum += priceSum;
        })
      }else{
        this.gdzc_sum = 0;
        this.argument_gdzc.dataSource = [];
      }



      if(data.collectionList == null || data.collectionList.length == 0) {
        this.activeKey = "2";
        if (data.incomeList == null || data.incomeList.length == 0) {
          this.activeKey = "3";
          if (data.otherPayList == null || data.otherPayList.length == 0) {
            this.activeKey = "4";
            if (data.payApplyList == null || data.payApplyList.length == 0) {
              this.activeKey = "5";
              if (data.recordList == null || data.recordList.length == 0) {
                this.activeKey = "7";
                if (data.contractList == null || data.contractList.length == 0) {
                  this.activeKey = "8";
                  if (data.invoiceRecordList == null || data.invoiceRecordList.length == 0) {
                    this.activeKey = "9";
                    if (data.invoiceIntoList == null || data.invoiceIntoList.length == 0) {
                      this.activeKey = "10";
                      if (data.deviceTempList == null|| data.deviceTempList.length == 0) {
                        this.activeKey = "1";

                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      /*var datas = [
        {age:'24',name:'阿三',sex:"男"},
        {age:'16',name:'阿梅',sex:"女"}
      ]
      var tableHeader = [
        [ '序号','姓名2','性别','年龄'],
      ]
      var dataList = []
      datas.forEach((item,index) => {
        dataList.push([ index+1, item.name,item.sex,item.age]);
      })
      let parm = {
        sheetName:'sheet1',
        tableHeader:tableHeader,
        dataList:dataList
      }
      let parm2 = {
        sheetName:'sheet2',
        tableHeader:tableHeader,
        dataList:dataList
      }
      let tableinfo = [];
      tableinfo.push(parm);
      tableinfo.push(parm2);
      dataToExcel_list("aaaa", tableinfo)*/
    },
    showDetail(index,record,type){
      switch (type){
        case "ygbz":{
          if(record.flowTemps.length>0){
            this.formArg.tableColumns = this.argument2_ygbz.tableColumns;
            this.visible = true;
            let arr = [];
            this.formArg.dataSource = arr.concat(record.flowTemps);
           /* let sum = 0;
            for(let i in this.argument2_ygbz.dataSource){
              sum += this.argument2_ygbz.dataSource[i].applyAmount;
            }*/
            this.sumMoney = record.applyAmount.toFixed(2)
          }
          else{
            this.$message.info("无数据");
          }
          break;
        }
        case "gdzc":{
          if(record.childreList.length>0){
            this.formArg.tableColumns = this.argument_gdzc2.tableColumns;
            this.visible = true;
            let arr = [];
            this.formArg.dataSource = arr.concat(record.childreList);
            this.sumMoney = record.priceSum.toFixed(2)
          }
          else{
            this.$message.info("无数据");
          }
          break;
        }
      }

    },
    downFile(){
      /** 项目收款记录 start ***/
      let tableColumns1 = this.argument_xmskjl.tableColumns;
      let tableHeader1 = this.dealWith_tableHeader(tableColumns1);
      let dataSource1 = this.argument_xmskjl.dataSource;
      let dataList1 = this.dealWith_dataList(tableColumns1,dataSource1);
      let tableinfo1 = {
        sheetName:'项目收款记录',
        tableHeader:tableHeader1,
        dataList:dataList1
      }
      /** 项目收款记录 end ***/

      /** 其他收入 start ***/
      let tableColumns2 = this.argument_qtsr.tableColumns;
      let tableHeader2 = this.dealWith_tableHeader(tableColumns2);
      let dataSource2 = this.argument_qtsr.dataSource;
      let dataList2 = this.dealWith_dataList(tableColumns2,dataSource2);
      let tableinfo2 = {
        sheetName:'其他收入',
        tableHeader:tableHeader2,
        dataList:dataList2
      }
      /** 其他收入 end ***/

      /** 其他支出 start ***/
      let tableColumns3 = this.argument_qtqc.tableColumns;
      let tableHeader3 = this.dealWith_tableHeader(tableColumns3);
      let dataSource3 = this.argument_qtqc.dataSource;
      let dataList3 = this.dealWith_dataList(tableColumns3,dataSource3);
      let tableinfo3 = {
        sheetName:'其他支出',
        tableHeader:tableHeader3,
        dataList:dataList3
      }
      /** 其他支出 end ***/

      /** 外协支付 start ***/
      let tableColumns4 = this.argument_wxzc.tableColumns;
      let tableHeader4 = this.dealWith_tableHeader(tableColumns4);
      let dataSource4 = this.argument_wxzc.dataSource;
      let dataList4 = this.dealWith_dataList(tableColumns4,dataSource4);
      let tableinfo4 = {
        sheetName:'外协支付',
        tableHeader:tableHeader4,
        dataList:dataList4
      }
      /** 外协支付 end ***/

      /** 员工报账 start ***/
      let tableColumns5 = this.argument_ygbz.tableColumns;
      let tableHeader5 = this.dealWith_tableHeader(tableColumns5);
      let dataSource5 = this.argument_ygbz.dataSource;
      let dataList5 = this.dealWith_dataList(tableColumns5,dataSource5);
      let tableinfo5 = {
        sheetName:'员工报账',
        tableHeader:tableHeader5,
        dataList:dataList5
      }
      /** 员工报账 end ***/

      /** 合同 start ***/
      let tableColumns7 = this.argument_ht.tableColumns;
      let tableHeader7 = this.dealWith_tableHeader(tableColumns7);
      let dataSource7 = this.argument_ht.dataSource;
      let dataList7 = this.dealWith_dataList(tableColumns7,dataSource7);
      let tableinfo7 = {
        sheetName:'合同',
        tableHeader:tableHeader7,
        dataList:dataList7
      }
      /** 合同 end ***/

      /** 销项发票 start ***/
      let tableColumns8 = this.argument_xxfp.tableColumns;
      let tableHeader8 = this.dealWith_tableHeader(tableColumns8);
      let dataSource8 = this.argument_xxfp.dataSource;
      let dataList8 = this.dealWith_dataList(tableColumns8,dataSource8);
      let tableinfo8 = {
        sheetName:'销项发票',
        tableHeader:tableHeader8,
        dataList:dataList8
      }
      /** 销项发票 end ***/

      /** 进项发票 start ***/
      let tableColumns9 = this.argument_jxfp.tableColumns;
      let tableHeader9 = this.dealWith_tableHeader(tableColumns9);
      let dataSource9 = this.argument_jxfp.dataSource;
      let dataList9 = this.dealWith_dataList(tableColumns9,dataSource9);
      let tableinfo9 = {
        sheetName:'进项发票',
        tableHeader:tableHeader9,
        dataList:dataList9
      }
      /** 进项发票 end ***/

      /** 固定资产 start ***/
      let tableColumns10 = this.argument_gdzc.tableColumns;
      let tableHeader10 = this.dealWith_tableHeader(tableColumns10);
      let dataSource10 = this.argument_gdzc.dataSource;
      let dataList10 = this.dealWith_dataList(tableColumns10,dataSource10);
      let tableinfo10 = {
        sheetName:'固定资产',
        tableHeader:tableHeader10,
        dataList:dataList10
      }
      /** 固定资产 end ***/


      let tableinfoList = [];
      tableinfo1.dataList.length > 0 ? tableinfoList.push(tableinfo1):[];
      tableinfo2.dataList.length > 0 ? tableinfoList.push(tableinfo2):[];
      tableinfo3.dataList.length > 0 ? tableinfoList.push(tableinfo3):[];
      tableinfo4.dataList.length > 0 ? tableinfoList.push(tableinfo4):[];
      tableinfo5.dataList.length > 0 ? tableinfoList.push(tableinfo5):[];
      tableinfo7.dataList.length > 0 ? tableinfoList.push(tableinfo7):[];
      tableinfo8.dataList.length > 0 ? tableinfoList.push(tableinfo8):[];
      tableinfo9.dataList.length > 0 ? tableinfoList.push(tableinfo9):[];
      tableinfo10.dataList.length > 0 ? tableinfoList.push(tableinfo10):[];
      dataToExcel_list(this.downFileName, tableinfoList)

    },
    /** 处理下载表头 **/
    dealWith_tableHeader(tableColumns){
      let tableHeader = [];
      if(tableColumns.length > 0){
        let data = [];
        for (const i in tableColumns) {
          if(tableColumns[i].tableView){
            if(tableColumns[i].title != "序号" && tableColumns[i].title != "操作"){
              data.push(tableColumns[i].title);
            }
          }
        }
        tableHeader.push(data);
      }
      return tableHeader;
    },
    /** 处理下载表数据 **/
    dealWith_dataList(tableColumns,dataSource){
      let dataList = [];
      if(dataSource.length <= 0){
        return dataList;
      }

      let dataIndex = [];
      if(tableColumns.length > 0){
        for (const i in tableColumns) {
          if(tableColumns[i].tableView){
            if(tableColumns[i].title != "序号"){
              let type = tableColumns[i].type;
              let col_info = {
                dataIndex:tableColumns[i].dataIndex,
                type:type,
              }
              if(type == "Enum"){
                col_info.enumTags = tableColumns[i].enumTags;
                col_info.enums = tableColumns[i].enums;
              }

              dataIndex.push(col_info);
            }
          }
        }
      }
      if(dataIndex.length <= 0){
        return dataList;
      }
      dataSource.forEach((item,index) => {
        let data = [];
        dataIndex.forEach((col_t,index_t) => {
          let type = col_t.type;
          let data_info = item[col_t.dataIndex];
          if(type == "String"){
            data.push(data_info);
          }else if(type == "Date"){
              // 处理时间
            data.push(timestamp(data_info));
          }else if(type == "Enum"){
            let enumTags = col_t.enumTags;
            let enums = col_t.enums;
            enums.forEach((enum_s,index_s)=>{
              if(enum_s == data_info){
                data.push(enumTags[index_s]);
              }
            })
          }else{
            data.push(data_info);
          }

        })
        dataList.push(data);
      })
      return dataList;
    },

  }
}
</script>

<style scoped>

</style>