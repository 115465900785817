/** 
* 自动生成文件，请勿修改 
* @name: 支付:外协支付申请
* @description: 外协支付申请(三期新增)
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:13条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const payApply = { 
	/** 1 summary:单条删除	method:post */ 
	delete : new APIObject("/api/payApply/delete.do"), 
	/** 2 summary:外协支出-统计每月度外协支出总计（各月度下总计）	method:post */ 
	outPayStat : new APIObject("/api/payApply/outPayStat.do"), 
	/** 3 summary:分页查询	method:post */ 
	queryPage : new APIObject("/api/payApply/queryPage.do"), 
	/** 4 summary:撤销提交（在没有任何人审核情况下，可以撤销）	method:post */ 
	cancelSubmit : new APIObject("/api/payApply/cancelSubmit.do"), 
	/** 5 summary:所有数据查询	method:post */ 
	queryAll : new APIObject("/api/payApply/queryAll.do"), 
	/** 6 summary:保存-（新增以往历史外协支付记录，无需银行卡，无需审核）	method:post */ 
	save : new APIObject("/api/payApply/save.do"), 
	/** 7 summary:查询需要当前登录用户审批的申请-分页	method:post */ 
	pageMyApproval : new APIObject("/api/payApply/pageMyApproval.do"), 
	/** 8 summary:单条数据查询	method:post */ 
	queryOne : new APIObject("/api/payApply/queryOne.do"), 
	/** 9 summary:支付-点击这里时会将改笔申请的付款金额同步到付款记录中	method:post */ 
	payConfirm : new APIObject("/api/payApply/payConfirm.do"), 
	/** 10 summary:提交	method:post */ 
	submit : new APIObject("/api/payApply/submit.do"), 
	/** 11 summary:查询需要当前登录用户审批的申请-不分页	method:post */ 
	queryMyApproval : new APIObject("/api/payApply/queryMyApproval.do"), 
	/** 12 summary:保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/payApply/saveOrUpd.do"), 
	/** 13 summary:根据申请id导出对应支付审批表	method:post */ 
	exportById : new APIObject("/api/payApply/exportById.do"), 
};
export default payApply;