/** 
* 自动生成文件，请勿修改 
* @name: 项目:合同管理模块
* @description: 项目-合同管理模块-增删改查
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:11条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const contractInfo = { 
	/** 1 summary:外协合同数量统计：年-月、个数、金额、已支付、未支付等）	method:post */ 
	statOutCount : new APIObject("/api/contractInfo/statOutCount.do"), 
	/** 2 summary:合同数量统计：年、月、个数、金额等）	method:post */ 
	statCount : new APIObject("/api/contractInfo/statCount.do"), 
	/** 3 summary:保存/修改-单个合同	method:post */ 
	saveOrUpd : new APIObject("/api/contractInfo/saveOrUpd.do"), 
	/** 4 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/contractInfo/batchDelete.do"), 
	/** 5 summary:查询-所有数据	method:post */ 
	queryAll : new APIObject("/api/contractInfo/queryAll.do"), 
	/** 6 summary:合同查询：按签订年度（月度）、项目id、收款状态（已收款、未收款（未全部收款））	method:post */ 
	queryByItem : new APIObject("/api/contractInfo/queryByItem.do"), 
	/** 7 summary:查询-单条数据	method:post */ 
	queryOne : new APIObject("/api/contractInfo/queryOne.do"), 
	/** 8 summary:合同统计：合同总金额、应收款金额、未收款金额	method:post */ 
	statistics : new APIObject("/api/contractInfo/statistics.do"), 
	/** 9 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/contractInfo/queryPage.do"), 
	/** 10 summary:保存/修改-批量	method:post */ 
	batchSaveOrUpd : new APIObject("/api/contractInfo/batchSaveOrUpd.do"), 
	/** 11 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/contractInfo/delete.do"), 
};
export default contractInfo;