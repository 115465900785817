<!--
* @program: tyh-oa
* @author: ly
* @description: projectViewer 项目一览 主界面
* @create: 2021-03-25 11:00
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="visibleType = !visibleType" class="search-item" type="default">
                    <template #icon>
                        <BarChartOutlined style="color:green"/>
                    </template>
                    项目分类统计
                </a-button>
                <a-button @click="refresh" class="search-item" shape="circle" type="default">
                    <template #icon>
                        <SyncOutlined title="重置"/>
                    </template>
                </a-button>
                <a-button @click="addData" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <PlusOutlined title="新增"/>
                    </template>
                </a-button>
                <a-tooltip title="有收款无合同的项目，除去了不需要合同的类型">
                    <a-badge :count="unSetterSource.length">
                        <a-button :type="unSetterSource === dataSource?'primary':''" @click="unSetterSourceShow"
                                  class="search-item"
                                  style="margin-left: 10px">
                            <div>有收款无合同</div>
                        </a-button>
                    </a-badge>
                </a-tooltip>
                <a-tooltip title="待支付的外协支付申请">
                    <a-badge :count="payApplySource.length">
                        <a-button @click="showPayApply" class="search-item" style="margin-left: 10px" type="default">
                            外协支付申请
                        </a-button>
                    </a-badge>
                </a-tooltip>
                <a-tooltip title="已完成工作，收款未完成的项目">
                    <a-badge :count="notReceived.length">
                        <a-button :type="notReceived === dataSource?'primary':''" @click="notReceivedShow"
                                  class="search-item"
                                  style="margin-left: 10px">
                            <div>待收款</div>
                        </a-button>
                    </a-badge>
                </a-tooltip>
                <div style="margin:0 5px">项目筛选：</div>
                <a-input @change="doSearch" placeholder="项目名称" style="width: 150px" v-model:value="defaultQuery.name" />
                <a-input @change="doSearch" placeholder="甲方名称" style="width: 150px" v-model:value="defaultQuery.partyInfoName" />
                <a-input @change="doSearch" placeholder="分配部门" style="width: 150px" v-model:value="defaultQuery.departmentName" />
                <QueryFilter :filter="filter" @change="doSearch"   v-model:value="defaultQuery"/>
            </div>
            <div v-if="stateVisible" style="display: flex;align-items: center;flex-wrap: wrap;justify-content: center;">
                项目个数：{{item_sum}}
                <a-divider style="height: 30px; background-color: #7cb305" type="vertical"/>
                预算总金额：{{itemBudget_sum.toFixed(2)}}
                <a-divider style="height: 30px; background-color: #7cb305" type="vertical"/>
                合同总金额：{{sumAmount_sum.toFixed(2)}}
                <a-divider style="height: 30px; background-color: #7cb305" type="vertical"/>
                等效合同总额：{{dxhte_sum.toFixed(2)}}
                <a-divider style="height: 30px; background-color: #7cb305" type="vertical"/>
                已收款总金额：{{sumCollect_sum.toFixed(2)}}
                <a-divider style="height: 30px; background-color: #7cb305" type="vertical"/>
                未收款总金额：{{sumCollectNo_sum.toFixed(2)}}
                <a-divider style="height: 30px; background-color: #7cb305" type="vertical"/>
                无合同但收款合计：{{unSetter_sum.toFixed(2)}}
            </div>
            <TwoParts :trans="[100,25]" @change="handleChange" pos="transform" ref="TwoParts">
                <template #left>
                    <TcBaseTable :data-source="tableSource" :download="true" :loading="loading" :page-size="15"
                                 :scroll="{x:300,y:620}" :table-columns="tableColumns" :view-model="true"
                                 ref="project_table">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button @click="showDetail(index,record)" class="action-button" type="link">
                                    <SearchOutlined class="pointer" title="查看"/>
                                </a-button>
                                <a-button @click="editDetail(index,record)" class="action-button" type="link">
                                    <EditFilled class="pointer" title="编辑"/>
                                </a-button>
                                <a-popconfirm
                                        @confirm="deleteData(index,record)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="是"
                                        title="确定删除吗?">
                                    <DeleteOutlined class="pointer" style="color: red" title="删除"/>
                                </a-popconfirm>
                            </div>
                        </template>
                    </TcBaseTable>
                </template>
                <template #right>
                    <project-tabs ref="local_tabs"/>
                </template>
            </TwoParts>
        </div>
    </div>
    <a-drawer
            :closable="false"
            :height="800"
            :title="null"
            placement="bottom"
            v-model:visible="visibleType">
        <projectStatistics :editable="true" :project-data="projectList" v-if="visibleType"/>
    </a-drawer>
    <a-drawer
            :closable="false"
            :height="800"
            placement="bottom"
            title="审核完成，待支付的外协支付申请"
            v-model:visible="visible">
        <OutSourcePayApply :data-source="payApplySource"/>
    </a-drawer>
</template>

<script>
    import projectStatistics from "./projectStatistics";
    import {
        BarChartOutlined,
        DeleteOutlined,
        EditFilled,
        PlusOutlined,
        SearchOutlined,
        SyncOutlined
    } from "@ant-design/icons-vue"
    import itemInfo from "../../../assets/api/itemInfo";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import item_info from "../../../assets/tables/item_info";
    import payApply from "../../../assets/api/payApply";
    import OutSourcePayApply from "./OutSourcePayApply";
    import QueryFilter from "../../assetsManager/assetsView/QueryFilter2";
    import contractType from "../contractType";
    import {companyIds,companyNames,companyColors} from "../../../assets/system/companies";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import projectTabs from "./projectTabs";
    import TwoParts from "../../_components/TwoParts";

    export default {
        name: "projectViewer",
        components: {
            projectStatistics, PlusOutlined, DeleteOutlined,
            OutSourcePayApply, QueryFilter,TcBaseTable,
            SearchOutlined, SyncOutlined, BarChartOutlined, EditFilled,
            projectTabs, TwoParts,
        },
        computed: {
            item_sum() {
                return this.tableSource.length;
            },
            itemBudget_sum() {//预算总金额
                let sum = 0;
                for (let item of this.tableSource) {
                    sum += item.itemBudget;
                }
                return sum;
            },
            sumAmount_sum() {//合同总金额
                let sum = 0;
                for (let item of this.tableSource) {
                    sum += item.sumAmount;
                }
                return sum;
            },
            dxhte_sum() {//等效合同总额
                let sum = 0;
                for (let item of this.tableSource) {
                    sum += item.dxhte;
                }
                return sum;
            },
            sumCollect_sum() {//已收款总金额
                let sum = 0;
                for (let item of this.tableSource) {
                    sum += item.sumCollect;
                }
                return sum;
            },
            sumCollectNo_sum() {//未收款总金额
                let sum = 0;
                for (let item of this.tableSource) {
                    sum += item.sumCollectNo;
                }
                return sum;
            },
            unSetter_sum() {//已收款，但是没有合同的
                let sum = 0;
                for (let item of this.unSetterSource) {
                    sum += item.sumCollect;
                }
                return sum;
            }
        },
        data() {
            return {
                projectList:[],
                loading:false,
                table: new item_info(),
                tableColumns:new item_info().getTableColumns(),
                condition: null,
                payApplySource: [],
                visible: false,
                defaultQuery: {
                    name:null,
                    partyInfoName:null,
                    departmentName:null,

                    itemType: undefined,
                    collecState: undefined,
                    contractState: undefined,
                    partyContract: undefined,
                    contractTypes: undefined,
                    sigCompany:undefined,
                    year: undefined
                },
                stateVisible: false,
                filter: {
                    itemType: {
                        name:"项目类型",
                        values:["0", "1", "2", "3"],
                        labels:["自研项目", "甲方项目", "劳务协作项目", "三方项目"],
                        colors:["cyan", "blue", "pink", "green"]
                    },
                    collecState: {
                        name:"有无收款记录",
                        values:["0", "1"],
                        labels:["无收款", "有收款"],
                        colors:["orange", "green"]
                    },
                    contractState:{
                        name:"合同情况",
                        values:["0","1","2"],
                        labels:["无","有","不需要"],
                        colors:["pink","green","cyan"]
                    },
                    partyContract: {
                        name:"合同附件",
                        values:["0", "1"],
                        labels:["未录入", "已录入"],
                        colors:["orange", "green"]
                    },
                    contractTypes: {
                        name:"已有合同类型",
                        values:contractType.value,
                        labels:contractType.label,
                        colors:contractType.color
                    },
                    sigCompany: {
                        name:"合同签署公司",
                        values:companyIds,
                        labels:companyNames,
                        colors:companyColors,
                        placeholder:"甲方/三方合同的签署公司"
                    },
                    //year:[]  查询结束后初始化
                },
                unSetterSource: [],   //有收款  无合同的项目
                notReceived:[],
                dataSource: [], //filter 过滤前的数据源
                tableSource:[],  //filter 过滤后展示在table的数据
            }
        },
        methods: {
            init() {
                this.loading = true;
                this.searchData = null;
                this.pageSize = 15;
                itemInfo.queryAll.requestPOST(this, {auditState: "1"}, res => {
                    if (res.data.flag) {
                        this.unSetterSource = [];
                        let years = []; //项目年度
                        let colors = [];
                        let list_info = res.data.data;
                        if (list_info.length > 0) {
                            list_info.forEach((item) => {
                                item.sumAmount = 0;
                                item.contractTypes = [];
                                if (!years.includes(item.year)) {
                                    years.push(item.year);
                                    colors.push("red");
                                }
                                let contractInfoDTOs = item.contractInfoDTOs;
                                if (contractInfoDTOs != null && contractInfoDTOs.length > 0) {
                                    item.partyContract = "1";
                                    let contractTypes = []; //合同类型
                                    for (let item_c of contractInfoDTOs) {
                                        contractTypes.push(item_c.type);
                                        if (item_c.type === '1' || item_c.type === '4') {
                                            item.partyInfoId = item_c.associatedKey;
                                            item.partyInfoName = item_c.associatedName;

                                        }
                                        if (item_c.type === '1' || item_c.type === '4' || item_c.type === '7') {
                                            item.sumAmount += item_c.amount;
                                            item.sigCompany = item_c.sigCompany;
                                            let index = companyIds.indexOf(item_c.sigCompany);
                                            item.sigCompanyName = companyNames[index];
                                        }
                                    }
                                    item.contractTypes = contractTypes;
                                } else {
                                    item.partyContract = "0";
                                }
                                item.sumCollectNo = item.sumAmount;

                                if (item.sumCollectNo >= item.sumCollect) {
                                    item.sumCollectNo -= item.sumCollect
                                } else {
                                    item.sumCollectNo = 0;
                                    if (item.contractState !== "2") {
                                        this.unSetterSource.push(item)
                                    }
                                }

                                item.collecState = item.sumCollect ? "1" : "0";

                                item.departmentName = item.itemTaskDTO?.departmentName
                            })
                        }
                        this.projectList = list_info.sort((a, b) => {
                            return b.startTime - a.startTime;
                        });
                        this.notReceived = this.projectList.filter(item => { //itemState 1 工作完成   collectionState 0 收款未完成  completeFlag 2 项目未完结
                            return item.itemState === "1" && item.collectionState === "0" && item.completeFlag !== "2"
                        });
                        this.dataSource = this.projectList;
                        this.filter.year = {
                            name:"年度",
                            values:years,
                            labels:years,
                            colors:colors
                        };
                        this.doSearch();
                    } else {
                        this.projectList = [];
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                });
                /**查询所有完成审核需要支付的数据*/
                /**
                 * submitState 提交状态 0：未提交；1：已提交，未提交的可删改
                 * approvalState 是否全部审核通过：0：未完成；1：已完成
                 * payState 支付状态：0：未支付；1：已支付
                 */
                this.payApplySource = [];
                let data = {
                    submitState: "1",
                    approvalState: "1",
                    payState: "0"
                };
                payApply.queryAll.requestPOST(this, data, res => {
                    if (res.data.flag) {
                        this.payApplySource = res.data.data;
                    }
                })
            },
            doSearch() {
                let source = this.projectList;
                if(this.dataSource === this.unSetterSource){
                    source  = this.unSetterSource;
                }
                if(this.dataSource === this.notReceived){
                    source  = this.notReceived;
                }
                // this.tableSource = source.filter(item => {
                //     let keys = Object.keys(this.defaultQuery);
                //     let filter = {};
                //     for(let key of keys){
                //         if(this.defaultQuery[key] !== null && this.defaultQuery[key] !== undefined && this.defaultQuery[key] !==""){
                //             filter[key] = this.defaultQuery[key]
                //         }
                //     }
                //     let fKeys = Object.keys(filter); // filter中有值的
                //     if(fKeys.length > 0){
                //         for (let key of keys) {
                //             if (item[key] && this.defaultQuery[key] && item[key].includes(this.defaultQuery[key])) {
                //                 return true;
                //             }
                //         }
                //         return false;
                //     }
                //     else{
                //         return true
                //     }
                // })
                source=source.map(el=>{
                    return {
                        ...el,
                        taskLeaderName:el.itemTaskDTO?.taskLeaderName?el.itemTaskDTO.taskLeaderName:'无'
                    }
                })
                this.tableSource = ArrayFilter(source,this.defaultQuery)
            },
            refresh() {
                this.year = null;
                this.init();
            },
            handleChange(pos) {
                if (pos === "transform") {
                    this.stateVisible = false;
                    this.tableColumns = [
                        new Column("序号", "_index", ColumnType.Index, false).setTableView(60),
                        new Column("项目名称", "name", ColumnType.String, false).setTableView(200),
                        new Column("操作", "actions", "actions", false).setTableView(80)
                    ]
                } else {
                    this.stateVisible = true;
                    this.tableColumns = this.table.getTableColumns();
                }
            },
            showDetail(index, record) {
                this.formData = record;
                this.$refs.local_tabs.handleOpen([
                    {tag: "项目基本信息", component: "projectInfo", viewModel: true, projectId: record.id},
                    {tag: "项目任务", component: "projectTaskInfo", viewModel: true, projectId: record.id},
                    {tag: "项目合同信息", component: "projectContract", viewModel: true, projectId: record.id},
                    {tag: "项目设计书信息", component: "projectDesignBook", viewModel: true, projectId: record.id},
                    /* {tag:"项目消耗一览",component:"projectUseUp",viewModel:true,projectId:record.id},
                     {tag:"项目收款一览",component:"projectIncome",viewModel:true,projectId:record.id},
                     {tag:"项目支付一览",component:"projectOutSourcePay",viewModel:true,projectId:record.id},
                     {tag:"项目收入发票",component:"projectInvoice",viewModel:true,projectId:record.id},
                     {tag:"项目支出发票",component:"projectInvoicePayoff",viewModel:true,projectId:record.id},*/
                    {tag: "质检情况", component: "QCManager", viewModel: true, projectId: record.id},
                    {tag: "项目其他信息一览", component: "projectOtherInfoList", viewModel: true, projectId: record.id},
                ]);
                this.$refs.TwoParts.transform();
            },
            editDetail(index, record) {
                this.formData = record;
                this.$refs.local_tabs.handleOpen([
                    {tag: "项目基本信息", component: "projectInfo", viewModel: false, projectId: record.id},
                    {tag: "项目合同信息", component: "projectContract", viewModel: false, projectId: record.id},
                    {tag: "项目设计书信息", component: "projectDesignBook", viewModel: false, projectId: record.id},
                    {tag: "项目其他信息一览", component: "projectOtherInfoList", viewModel: false, projectId: record.id},
                    /*{tag:"项目收款一览",component:"projectIncome",viewModel:false,projectId:record.id},
                    {tag:"项目支付一览",component:"projectOutSourcePay",viewModel:false,projectId:record.id},
                    {tag:"项目收入发票",component:"projectInvoice",viewModel:false,projectId:record.id},
                    {tag:"项目支出发票",component:"projectInvoicePayoff",viewModel:false,projectId:record.id},*/
                ]);
                this.$refs.TwoParts.transform();
            },
            addData() {
                this.formData = this.table.getFormData();
                this.$refs.local_tabs.handleOpen([
                    {tag: "新增项目", component: "projectInfo"},
                ]);
            },
            deleteData(index, record) {
                let data = {id: record.id};
                itemInfo.delete.requestPOSTUrlParam(this, data, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.tableSource.splice(index, 1);
                    } else {
                        this.$message.error(res.data.msg)
                    }
                });
            },
            showPayApply() {
                if (this.payApplySource.length > 0) {
                    this.visible = true
                } else {
                    this.$message.error("没有支付申请")
                }
            },
            unSetterSourceShow() {
                if (this.dataSource !== this.unSetterSource) {
                    this.dataSource = this.unSetterSource;
                } else {
                    this.dataSource = this.projectList
                }
                this.doSearch();
            },
            notReceivedShow(){
                if (this.dataSource !== this.notReceived) {
                    this.dataSource = this.notReceived;
                } else {
                    this.dataSource = this.projectList
                }
                this.doSearch();
            }
        },
        created() {
            this.init()
        }
    }
    /**
     * 通过filter 找到满足条件的 array 中的元素
     * @param array 中的元素为object 例如：[{title:"中色",content:"刘"},{title:"lan",content:"王"}]
     * @param filer 为object 例如{title:"中色",content:"刘"}
     * @returns {*}
     */
    function ArrayFilter(array,filer) {
        let keys = Object.keys(filer);
        let tempArray = array;
        for(let key of keys){
            if(filer[key]){
                let result=Array.isArray(filer[key])?filer[key].join(','):filer[key]
                if(result){
                    tempArray = tempArray.filter(item=>{
                        if(item[key]){
                            return !Array.isArray(filer[key])?item[key].includes(filer[key]): filer[key].join(',').includes(item[key])
                        }
                        else{
                            return false
                        }
                    })
                }
            }
        }
        return tempArray
    }
</script>

<style scoped>
    :global(.oa-window .main-local) {
        height: 90% !important;
    }

    :global(.oa-window .main-local .left-part1) {
        height: 104% !important;
    }
</style>
