/** 
* 自动生成文件，请勿修改 
* @name: 研发部软件:软件工具管理
* @description: 软件工具管理-增删改查
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const softwareTools = { 
	/** 1 summary:保存/修改-批量	method:post */ 
	batchSaveOrUpd : new APIObject("/api/softwareTools/batchSaveOrUpd.do"), 
	/** 2 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/softwareTools/delete.do"), 
	/** 3 summary:查询-所有数据	method:post */ 
	queryAll : new APIObject("/api/softwareTools/queryAll.do"), 
	/** 4 summary:查询-单条	method:post */ 
	queryOne : new APIObject("/api/softwareTools/queryOne.do"), 
	/** 5 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/softwareTools/queryPage.do"), 
	/** 6 summary:保存/修改-单条	method:post */ 
	saveOrUpd : new APIObject("/api/softwareTools/saveOrUpd.do"), 
	/** 7 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/softwareTools/batchDelete.do"), 
};
export default softwareTools;