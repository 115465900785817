/** 
* 自动生成文件，请勿修改 
* @name: 财务:资产、负债、权益具体科目期初余额
* @description: 产、负债、权益具体科目期初余额======资产负债表使用
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:6条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const assetsBalance = { 
	/** 1 summary:试算平衡	method:post */ 
	calBalance : new APIObject("/api/assetsBalance/calBalance.do"), 
	/** 2 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/assetsBalance/delete.do"), 
	/** 3 summary:查询-单条	method:post */ 
	queryOne : new APIObject("/api/assetsBalance/queryOne.do"), 
	/** 4 summary:查询-全部不分页	method:post */ 
	queryAll : new APIObject("/api/assetsBalance/queryAll.do"), 
	/** 5 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/assetsBalance/batchDelete.do"), 
	/** 6 summary:保存/修改-单条	method:post */ 
	batchSave : new APIObject("/api/assetsBalance/batchSave.do"), 
};
export default assetsBalance;