/** 
* 自动生成文件，请勿修改 
* @name: 项目:发票开具记录管理模块(销项发票)
* @description: 发票开具记录管理模块-增删改查(二期新增)
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const invoiceRecord = { 
	/** 1 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/invoiceRecord/batchDelete.do"), 
	/** 2 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/invoiceRecord/delete.do"), 
	/** 3 summary:查询-不分页（全部）	method:post */ 
	queryAll : new APIObject("/api/invoiceRecord/queryAll.do"), 
	/** 4 summary:销项发票数量统计：年、月、条数、金额等）	method:post */ 
	statCount : new APIObject("/api/invoiceRecord/statCount.do"), 
	/** 5 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/invoiceRecord/queryPage.do"), 
	/** 6 summary:根据id查询	method:post */ 
	queryById : new APIObject("/api/invoiceRecord/queryById.do"), 
	/** 7 summary:查询-开票收款情况	method:post */ 
	collectSituation : new APIObject("/api/invoiceRecord/collectSituation.do"), 
	/** 8 summary:新增/修改-单条	method:post */ 
	saveOrUpdate : new APIObject("/api/invoiceRecord/saveOrUpdate.do"), 
	/** 9 summary:冲红-单条	method:post */ 
	flushRed : new APIObject("/api/invoiceRecord/flushRed.do"), 
};
export default invoiceRecord;