/** 
* 自动生成文件，请勿修改 
* @name: 新增:部门其他收入/支出管理(包括部门间流转)
* @description: 部门其他收入/支出管理
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:5条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const depIncomePay = { 
	/** 1 summary:新增（只能新增支出记录）	method:post */ 
	save : new APIObject("/api/depIncomePay/save.do"), 
	/** 2 summary:删除（不能删除转账记录）	method:post */ 
	delete : new APIObject("/api/depIncomePay/delete.do"), 
	/** 3 summary:根据id查询	method:post */ 
	queryOne : new APIObject("/api/depIncomePay/queryOne.do"), 
	/** 4 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/depIncomePay/queryPage.do"), 
	/** 5 summary:查询-不分页（全部）	method:post */ 
	queryAll : new APIObject("/api/depIncomePay/queryAll.do"), 
};
export default depIncomePay;