/** 
* 自动生成文件，请勿修改 
* @name: 任务:任务-月度-员工完成占比（月度绩效）
* @description: 任务:任务-月度-员工完成占比（月度绩效）
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:15条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const taskMonthUser = { 
	/** 1 summary:修改拟下绩效-单条	method:post */ 
	modifyMoney : new APIObject("/api/taskMonthUser/modifyMoney.do"), 
	/** 2 summary:查询-项目绩效分配情况表	method:post */ 
	itemPersonMoney : new APIObject("/api/taskMonthUser/itemPersonMoney.do"), 
	/** 3 summary:查询-单条	method:post */ 
	queryOne : new APIObject("/api/taskMonthUser/queryOne.do"), 
	/** 4 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/taskMonthUser/queryPage.do"), 
	/** 5 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/taskMonthUser/delete.do"), 
	/** 6 summary:导出-年度绩效汇总（个人）	method:post */ 
	personExport : new APIObject("/api/taskMonthUser/personExport.do"), 
	/** 7 summary:新增-单条	method:post */ 
	saveOrUpd : new APIObject("/api/taskMonthUser/saveOrUpd.do"), 
	/** 8 summary:查询-分页(任务负责人查询时不显示钱，只显示比例)	method:post */ 
	queryPageOnlyRatio : new APIObject("/api/taskMonthUser/queryPageOnlyRatio.do"), 
	/** 9 summary:查询-不分页(任务负责人查询时不显示钱，只显示比例)	method:post */ 
	queryAllOnlyRatio : new APIObject("/api/taskMonthUser/queryAllOnlyRatio.do"), 
	/** 10 summary:查询-部门目标产值，实际产值，用于更新部门负责人绩效得分	method:post */ 
	queryDep : new APIObject("/api/taskMonthUser/queryDep.do"), 
	/** 11 summary:查询-年度个人绩效汇总表	method:post */ 
	personReport : new APIObject("/api/taskMonthUser/personReport.do"), 
	/** 12 summary:导出-项目-个人分配绩效报表导出	method:post */ 
	itemPersonExport : new APIObject("/api/taskMonthUser/itemPersonExport.do"), 
	/** 13 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/taskMonthUser/batchDelete.do"), 
	/** 14 summary:保存/修改-批量(一次新增/修改一个任务的就行)	method:post */ 
	batchSaveOrUpd : new APIObject("/api/taskMonthUser/batchSaveOrUpd.do"), 
	/** 15 summary:查询-不分页	method:post */ 
	queryAll : new APIObject("/api/taskMonthUser/queryAll.do"), 
};
export default taskMonthUser;