/**
 * @program: TYH_office_automation
 * @author: ly
 * @description: conf
 * @create: 2024-03-05 10:06
 * 用于设置OA的配置文件
 **/

// let conf = {
//     systemName:"贵州中色蓝图办公系统",
//     apiBaseUrl:"../tyhoa_ht",
//     fileBaseUrl:"../tyhoa_file/",
//     fileReplacePath:"C:/apache-tomcat-8.5.38-2020/webapps/tyhoa_file/"
// };
// let conf = {
//     systemName:"质检分公司办公系统",
//     apiBaseUrl:"../zjoa_ht",
//     fileReplacePath:"D:/third_systems/resources/zjoa/webapps/tyhoa_file/"
// };
// let conf = {
//     systemName:"重庆公司办公系统",
//     apiBaseUrl:"../cqoa_ht",
//     fileReplacePath:"D:/third_systems/resources/cqoa/webapps/tyhoa_file/"
// };
let conf = {
    systemName:"餐饮办公系统",
    apiBaseUrl:"../cyoa-api",
    fileBaseUrl:"../cyoa-file/",
    fileReplacePath:"D:/apache-tomcat-9.0.87_19002/webapps/cyoa-file/"
};

export default conf

