/** 
* 自动生成文件，请勿修改 
* @name: 财务:公司除项目收款外的其他收入记录模块
* @description: 公司除项目收款外的其他收入记录模块-增删改查
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const income = { 
	/** 1 summary:其他收入-统计每月度收入总计（月度下类别总计）	method:post */ 
	incomeStat : new APIObject("/api/income/incomeStat.do"), 
	/** 2 summary:新增/修改-单条	method:post */ 
	saveOrUpdate : new APIObject("/api/income/saveOrUpdate.do"), 
	/** 3 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/income/batchDelete.do"), 
	/** 4 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/income/delete.do"), 
	/** 5 summary:根据id查询	method:post */ 
	queryOne : new APIObject("/api/income/queryOne.do"), 
	/** 6 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/income/queryPage.do"), 
	/** 7 summary:查询-不分页（全部）	method:post */ 
	queryAll : new APIObject("/api/income/queryAll.do"), 
};
export default income;