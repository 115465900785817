/** 
* 自动生成文件，请勿修改 
* @name: 项目:部门兑付记录模块
* @description: 兑付记录模块(三期新增)
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:6条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const departPayment = { 
	/** 1 summary:查询-不分页	method:post */ 
	queryAll : new APIObject("/api/departPayment/queryAll.do"), 
	/** 2 summary:新增-单条	method:post */ 
	saveOrUpd : new APIObject("/api/departPayment/saveOrUpd.do"), 
	/** 3 summary:查询-单条	method:post */ 
	queryOne : new APIObject("/api/departPayment/queryOne.do"), 
	/** 4 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/departPayment/queryPage.do"), 
	/** 5 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/departPayment/delete.do"), 
	/** 6 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/departPayment/batchDelete.do"), 
};
export default departPayment;