<!--
* @program: office_automation
* @author: ly
* @component:QueryFilter
* @description:
* @create: 2022-06-17 09:45
-->
<template>
    <a-tooltip title="过滤条件">
        <a-popover trigger="click" placement="bottom">
            <template #content>
                <div>过滤条件：</div>
                <div v-for="(item,index) in filter" :key="index">
                    <div style="display: flex;align-items: center;width: 300px;border-bottom:1px solid #cecece ">
                        <div style="width: 150px">{{item.name}}:</div>
                        <a-select v-model:value="fr[index]" mode="multiple"
                                  style="width: 100%"
                                  :placeholder="item.placeholder?item.placeholder:'选择条件'"
                                  @change="radioChange">
                            <a-select-option v-for="(kk,idx) in item.values" :value="kk" :key="kk">
                                {{item.labels[idx]}}
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
            </template>
            <a-button type="link">
                <template #icon>
                    <OrderedListOutlined/>
                </template>
            </a-button>
        </a-popover>
    </a-tooltip>
    <div style="overflow-x: auto;width:560px;display: flex;padding: 0px 10px"  >
        <a-tag v-for="item in selectedLabel" :key="item.seg" :color="item.color" closable @close="remove(item)">
            {{item.label}}
        </a-tag>
    </div>
</template>

<script>
    import {OrderedListOutlined} from "@ant-design/icons-vue"

    export default {
        name: "QueryFilter",
        props: ["filter", "value", "disabled",'mult'],
        emits: ["update:value", "change"],
        components: {
            OrderedListOutlined
        },
        computed: {
            selectedLabel() {
                let arr = [];
                for (let i in this.fr) {
                    if (this.fr[i] && this.filter[i]) {
                        for (let j in this.fr[i]) {
                            let index = this.filter[i].values.indexOf(this.fr[i][j]);
                            let label = this.filter[i].labels[index];
                            let color = this.filter[i].colors[index];
                            if (label) {
                                arr.push({
                                    seg: i,
                                    childIndex: j,
                                    label: label,
                                    color: color,
                                })
                            }
                        }

                    }
                }
                return arr;
            }
        },
        watch: {
            filter() {
                this.init()
            },
            selectedLabel: {
                handler() {
                    this.selectedLabel = [...this.selectedLabel]
                },
                deep: true
            },
            '$props.value': {
                handler() {
                    this.fr = {...this.value}
                },
                deep: true
            }
        },
        data() {
            return {
                fr: {},
            }
        },
        methods: {
            init() {
                this.fr = {...this.value};
            },
            radioChange(arr) {
                this.$emit("update:value", this.fr);
                this.$emit("change");
            },
            remove(item) {
                this.fr[item.seg] = this.fr[item.seg].filter((im, i) => i != item.childIndex);
                let keys = Object.keys(this.fr)
                for (let key of keys) {
                    if (this.fr[key]) {
                        if (Array.isArray(this.fr[key]) && this.fr[key].length == 0) {
                            this.fr[key] = undefined
                        }
                    }

                }
                this.$emit("update:value", this.fr);
                this.$emit("change");
                this.forceUpdate()
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>
