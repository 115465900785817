/** 
* 自动生成文件，请勿修改 
* @name: 系统:用户额外信息管理（人事需要）
* @description: 用户额外信息管理（人事需要）（三期新增）
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:6条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const userExtra = { 
	/** 1 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/userExtra/queryPage.do"), 
	/** 2 summary:查询-不分页（全部）	method:post */ 
	queryAll : new APIObject("/api/userExtra/queryAll.do"), 
	/** 3 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/userExtra/batchDelete.do"), 
	/** 4 summary:新增/修改-单条	method:post */ 
	saveOrUpdate : new APIObject("/api/userExtra/saveOrUpdate.do"), 
	/** 5 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/userExtra/delete.do"), 
	/** 6 summary:根据id查询	method:post */ 
	queryById : new APIObject("/api/userExtra/queryById.do"), 
};
export default userExtra;