/** 
* 自动生成文件，请勿修改 
* @name: 微信:小程序菜单管理模块
* @description: 小程序菜单管理模块
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:10条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const wxMenu = { 
	/** 1 summary:查询-所有菜单树	method:post */ 
	queryAllTree : new APIObject("/api/wxMenu/queryAllTree.do"), 
	/** 2 summary:删除-单条菜单	method:post */ 
	delete : new APIObject("/api/wxMenu/delete.do"), 
	/** 3 summary:查询-当前登录用户有权限的菜单树	method:post */ 
	menuLoginUser : new APIObject("/api/wxMenu/menuLoginUser.do"), 
	/** 4 summary:新增/编辑菜单-单个	method:post */ 
	saveOrUpd : new APIObject("/api/wxMenu/saveOrUpd.do"), 
	/** 5 summary:查询-（不分页）	method:post */ 
	queryAll : new APIObject("/api/wxMenu/queryAll.do"), 
	/** 6 summary:查询-某用户有权限的菜单树	method:post */ 
	menuTreeByUser : new APIObject("/api/wxMenu/menuTreeByUser.do"), 
	/** 7 summary:查询-根据角色id查询有权限的菜单(及有权限的操作按钮)	method:post */ 
	menuByRole : new APIObject("/api/wxMenu/menuByRole.do"), 
	/** 8 summary:查询-单条	method:post */ 
	queryOne : new APIObject("/api/wxMenu/queryOne.do"), 
	/** 9 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/wxMenu/batchDelete.do"), 
	/** 10 summary:查询-（分页）	method:post */ 
	queryPage : new APIObject("/api/wxMenu/queryPage.do"), 
};
export default wxMenu;