<!--
* @program: tyh-oa
* @author: ly
* @component:billViewerAll
* @description: 报账审核-所有账单一览  全部人账单都能看到
* @create: 2021-04-26 16:59
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <SearchBar :search-options="searchOptions" v-model:value="searchData"/>
                <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
                    <template #icon>
                        <SearchOutlined/>
                    </template>
                </a-button>
                <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
                    <template #icon>
                        <SyncOutlined/>
                    </template>
                </a-button>
                <span>月份123：</span>
                <MonthPicker @change="doSearch" style="width: 120px" v-model:value="queryOptions.recordYearMonth"/>
                <DepartmentPicker @change="doSearch" v-model:value="departmentId"/>
                <QueryFilter :filter="filter" v-model:value="queryOptions" @change="doSearch"/>
            </div>
            <TwoParts ref="TwoParts" @change="handleChange" pos="transform">
                <template #left>
                    <TcBaseTable ref="bill_table" :view-model="true" :page-size="pageSize"
                                 @pageChange="handlePageChange"
                                 :loading="loading" :data-source="dataSource" :table-columns="columns">
                        <template #action="{index,record}">
                            <div class="action-column">
                                <a-button @click="showDetail(record)" class="action-button" type="link">
                                    <template #icon>
                                        <SearchOutlined class="pointer"/>
                                    </template>
                                </a-button>
                                <a-popconfirm
                                        @confirm="downloadFlows(record)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="是"
                                        title="导出流水？">
                                    <a-button class="action-button" type="link">
                                        <DownloadOutlined class="pointer"/>
                                    </a-button>
                                </a-popconfirm>
                                <a-popconfirm
                                        @confirm="submit(index,record)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="提交"
                                        title="提交审核？"
                                        v-if="checkAction(record)">
                                    <a-tooltip title="提交审核">
                                        <a-button class="action-button" style="color: green" type="link">
                                            <ArrowUpOutlined class="pointer"/>
                                        </a-button>
                                    </a-tooltip>
                                </a-popconfirm>
                            </div>
                        </template>
                    </TcBaseTable>
                </template>
                <template #right>
                    <billTabs ref="local_tabs" @event="eventHandle"/>
                </template>
            </TwoParts>
        </div>
    </div>
</template>

<script>
    import api_item_finance_record from "../../../assets/api/record";
    import billTabs from "../billComponents/billTabs";
    import item_finance_record from "../../../assets/tables/item_finance_record_2";
    import api_item_finance_record_flow from "../../../assets/api/recordFlow";
    import {dateFormat} from "../../../assets/utils/general";
    import TwoParts from "../../_components/TwoParts";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import QueryFilter from "../../assetsManager/assetsView/QueryFilter3";
    import SearchBar from "../../../components/SearchBar/SearchBar";
    import MonthPicker from "../../_components/MonthPicker";
    import ICONS from "../../_components/ICONS";
    import {SearchSegment} from "../../../assets/tables/parts/tableUtils";
    import userInfo from "../../../assets/api/userInfo";
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import DepartmentPicker from "../../../components/UserPicker/DepartmentPicker";

    export default {
        name: "billViewerAll",
        mixins: [ICONS],
        components: {
            billTabs, TwoParts,
            QueryFilter, SearchBar, MonthPicker, TcBaseTable,
            DepartmentPicker
        },
        computed: {
            queryData() {
                let data = {...this.queryOptions};
                Object.keys(data).map(item => {
                    if (!data[item]) {
                        delete data[item]
                    }
                });
                data.departmentId = this.departmentId;
                return data;
            }
        },
        data() {
            return {
                table: new item_finance_record(),
                searchOptions: [
                    new SearchSegment("账单标题", "title"),
                    new SearchSegment("用户名", "userId")
                ],
                searchData: null,
                filter: {
                    entryState: {
                        name: "入账状态",
                        values: ["0", "1", "4"],
                        labels: ["未入账", "对公入账", "私人入账"],
                        colors: ["purple", "blue", "green"]
                    },
                    approvalState: {
                        name: "审核状态",
                        values: ["0", "1"],
                        labels: ["未审核", "已审核"],
                        colors: ["red", "green"]
                    },
                    submitState: {
                        name: "提交审核状态",
                        values: ["0", "1"],
                        labels: ["未提交", "已提交"],
                        colors: ["purple", "green"]
                    },
                },
                dataSource: [],
                queryOptions: {
                    //entryState:null, //入账状态（0：未入账；1：对公入账；2：拒绝入账；3：撤销入账 ;4：私人入账）
                    approvalState: "1", //审核状态（0：未完成；1：已完成）
                    submitState: "1", //提交状态（0：未提交；1：已提交）
                    recordYearMonth: dateFormat('YYYY-MM', new Date()), // 账单所属年月
                },
                departmentId: null,
                api_page: api_item_finance_record.queryPage,
                pageSize: 15,
                columns: [
                    new Column("用户名", "userName", ColumnType.String, false).setTableView(80),
                    new Column("账单标题", "title", ColumnType.String, true).setTableView(150),
                    new Column("操作", "actions", "actions", false).setTableView(80)
                ],
                loading: false,
                totalMoneryBX: '',//报销总金额
            }
        },
        methods: {
            init() {
                let data = {
                    page: 1,
                    limit: this.pageSize
                };
                this.loading = true;

                this.getTotalSum({urlParam: this.queryData, formParam: data})
                this.api_page.requestPOSTBothParam(this, {urlParam: this.queryData, formParam: data}, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        this.dataSource = res.data.data;
                        this.$refs.bill_table.setTotalSize(res.data.count);
                        this.$refs.bill_table.setCurrentPage(1);
                    } else {
                        this.dataSource = []
                    }
                })
            },
            doSearch() {
                if (Object.keys(this.searchData)[0] === "userId") {
                    let data = {
                        queryText: this.searchData.userId,
                        disableState: "1", loginState: "0"
                    };

                    userInfo.queryAll.requestPOSTUrlParam(this, data, res => {
                        if (res.data.flag) {
                            res.data.data = res.data.data.filter(item => item.id !== "2"); //删除超级管理员账号
                            if (res.data.data.length === 1) {
                                let userId = res.data.data[0].id;
                                let searchOption = {userId: userId, useState: "1"};//useState:"1" 已借出
                                Object.assign(searchOption, this.queryData);
                                this.loading = true;
                                let data = {
                                    page: 1,
                                    limit: this.pageSize
                                };

                                api_item_finance_record.queryAll.requestPOSTBothParam(this, {
                                    urlParam: searchOption,
                                    formParam: data
                                }, res => {
                                    if (res.data.flag) {
                                        this.dataSource = res.data.data;
                                        this.$refs.bill_table.setTotalSize(res.data.count);
                                        this.$refs.bill_table.setCurrentPage(1);
                                    } else {
                                        this.$message.error(res.data.msg);
                                    }
                                    this.loading = false;
                                });
                            } else {
                                this.$message.info("查无此人");
                            }
                        } else {
                            this.$message.info("查无此人");
                        }
                    })
                } else {
                    this.loading = true;
                    let searchOption = {...this.searchData};
                    Object.assign(searchOption, this.queryData);
                    let data = {
                        page: 1,
                        limit: this.pageSize
                    };
                    this.getTotalSum({urlParam: searchOption, formParam: data})
                    this.api_page.requestPOSTBothParam(this, {urlParam: searchOption, formParam: data}, res => {
                        if (res.data.flag) {
                            this.dataSource = res.data.data;
                            this.$refs.bill_table.setTotalSize(res.data.count);
                            this.$refs.bill_table.setCurrentPage(1);
                        } else {
                            this.$message.error(res.data.msg);
                        }
                        this.loading = false;
                    });
                }
            },
            refresh() {
                this.init();
                this.$refs.TwoParts.reset();
            },
            submit(index, record) {
                record.submitState = "1";
                api_item_finance_record.saveOrUpd.requestPOST(this, record, res => {
                    if (res.data.flag) {
                        record.submitState = "1";
                        this.$message.success(res.data.msg);
                    } else {
                        record.submitState = "0";
                        this.$message.error(res.data.msg);
                    }
                });
            },
            showDetail(record) {
                this.$refs.local_tabs.handleOpen([
                    {tag: "账单详情", component: "personalBillViewer", record: record},
                ]);
                this.$refs.TwoParts.transform();
            },
            downloadFlows(record) {
                let data = {recordId: record.id};
                let fileName = record.title + ".xls";
                api_item_finance_record_flow.exportExcelByRecord.fileDownloadGet(this, data, fileName);
            },
            handlePageChange(arg) {
                if (this.dataSource.length <= this.pageSize) {
                    let searchOption = {...this.searchData};
                    Object.assign(searchOption, this.queryData);
                    this.loading = true;
                    this.api_page.requestPOSTBothParam(this, {urlParam: searchOption, formParam: arg}, res => {
                        if (res.data.flag) {
                            this.dataSource = res.data.data;
                            this.$refs.bill_table.setTotalSize(res.data.count);
                        } else {
                            this.$message.error(res.data.msg);
                        }
                        this.loading = false;
                    });
                }

            },
            eventHandle({type, arg}) {
                switch (type) {
                    case "show_p_bill": {
                        if (arg.pid) {
                            let data = {id: arg.pid};
                            api_item_finance_record.queryOne.requestPOSTUrlParam(this, data, res => {
                                if (res.data.flag) {
                                    this.formData = res.data.data;
                                    this.$refs.local_tabs.handleOpen([
                                        {tag: "账单详情", component: "personalBillViewer", record: res.data.data},
                                    ]);
                                }
                            })
                        } else {
                            this.$message.error("还未绑定父账单")
                        }
                    }
                }
            },
            handleChange(pos) {
                if (pos === "transform") {
                    this.columns = [
                        //new Column("序号", "_index", ColumnType.Index, false).setTableView(60),
                        new Column("用户名", "userName", ColumnType.String, false).setTableView(80),
                        new Column("账单标题", "title", ColumnType.String, true).setTableView(150),
                        new Column("操作", "actions", "actions", false).setTableView(80)
                    ]
                } else {
                    this.columns = this.table.getTableColumns();
                }
            },

            checkAction(record) {
                return record.submitState === "0"
            },
            getTotalSum(params) {
                api_item_finance_record.querySumAmountCommon.requestPOSTBothParam(this, params, res => {
                    if (res.data.flag) {
                        this.totalMoneryBX = res.data.data.sumAmount;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            }
        },
        mounted() {
            this.init()
        }
    }
</script>

<style scoped>

</style>
