<!--
* @program: office_automation 
* @author: ly
* @component:UserExtraManager 
* @description: 
* @create: 2023-02-23 10:58
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div  class="search-bar">
                <a-button class="search-item" type="dashed"  @click="init">
                    <template #icon><SyncOutlined /></template>
                </a-button>
                <a-button class="search-item" type="text" >
                    选择部门
                </a-button>
                <a-select v-model:value="department" style="width: 250px"
                          class="search-item"
                          @change="departmentSelect">
                    <a-select-option v-for="item in departments"  :key="item.id">
                        {{item.fullName}}
                    </a-select-option>
                </a-select>
                <span style="margin-left: 10px">状态：</span>
                <a-radio-group @change="radioChange" class="search-item" v-model:value="defaultQuery.disableState">
                    <a-radio value="1">在职</a-radio>
                    <a-radio value="0">离职</a-radio>
                </a-radio-group>
            </div>
            <div style="height: calc(100% - 60px);">
                <TcBaseTable :table-columns="tableColumn" :loading="loading"
                             :data-source="dataSource" :view-model="true" :page-size="15" >
                    <template #action="{record}">
                        <div class="action-column">
                            <a-tooltip title="显示简历">
                                <a-button class="action-button" type="link" @click="showDetail(record)">
                                    <template #icon><SearchOutlined /></template>
                                </a-button>
                            </a-tooltip>
                            <a-tooltip title="信息编辑">
                                <a-button class="action-button" type="link" @click="editDetail(record)">
                                    <template #icon><EditFilled /></template>
                                </a-button>
                            </a-tooltip>
                            <a-tooltip title="文件上传">
                                <a-button class="action-button" type="link" @click="fileAdd(record)">
                                    <template #icon><FileAddOutlined /></template>
                                </a-button>
                            </a-tooltip>
                        </div>
                    </template>
                </TcBaseTable>
            </div>
        </div>
    </div>
    <a-drawer v-model:visible="visible"
              width="50%"
              placement="right"
              :closable="false"
    >
        <FcForm style="width:600px" :form-columns="formColumns" v-model:form-data="mFormData" :view-model="viewModel" >
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </FcForm>
    </a-drawer>
    <a-drawer v-model:visible="visible2"
              width="50%"
              placement="right"
              :closable="false"
    >
        <UserProfile :record="mFormData" :name="currentRecord.name" :state="defaultQuery.disableState"/>
    </a-drawer>
    <a-modal v-model:visible="visible3"
             title="文件上传"
             destroyOnClose
             :footer="null">
        <div>
            <div>
                <div style="font-weight: bold;color: #001A47;">
                    照片(JPG,PNG格式)
                    <a-popconfirm
                            ok-text="是"
                            cancel-text="否"
                            title="确认删除?"
                            @confirm="deleteAvatar">
                        <a-button class="action-button" type="link">
                            删除
                        </a-button>
                    </a-popconfirm>
                </div>
                <div style="display: flex">
                    <a-upload
                            style="width:128px"
                            :action="actionUrl"
                            list-type="picture-card"
                            v-model:file-list="fileList"
                            :accept="accept"
                            @change="handleChange"
                            :showUploadList="false"
                            withCredentials
                    >
                        <div><PlusOutlined /><div>上传</div></div>
                    </a-upload>
                    <a-image v-if="previewImage" :alt="previewImage"  :src="previewImage" />
                </div>
            </div>
            <div>
                <div style="font-weight: bold;color: #001A47;">
                    简历原件（PDF格式）
                    <a-popconfirm
                            ok-text="是"
                            cancel-text="否"
                            title="确认删除?"
                            @confirm="deletePDF">
                        <a-button class="action-button" type="link">
                            删除
                        </a-button>
                    </a-popconfirm>
                </div>
                <div style="display: flex">
                    <a-upload
                            style="width:128px"
                            :action="actionUrl"
                            list-type="picture-card"
                            v-model:file-list="fileList2"
                            :accept="accept2"
                            @change="handleChange2"
                            :showUploadList="false"
                            withCredentials
                    >
                        <div><PlusOutlined /><div>上传</div></div>
                    </a-upload>
                    <embed v-if="previewPDF" :alt="previewPDF"  :src="previewPDF" />
                </div>
            </div>
        </div>
    </a-modal>
</template>

<script>

    import TcBaseTable from "../../../components/table/TcBaseTableFilter";
    import FcForm from "../../../components/form/FcForm";
    import {
        EditFilled,
        SearchOutlined,
        FileAddOutlined,PlusOutlined,SyncOutlined
    } from "@ant-design/icons-vue";
    import userInfo from "../../../assets/api/userInfo";
    import sys_user_info_with_extra from "../../../assets/tables/sys_user_info_with_extra";
    import sys_user_info_extra2 from "../../../assets/tables/sys_user_info_extra2";
    import sys_user_info_with_extra_left from "../../../assets/tables/sys_user_info_with_extra_left";
    import userExtra from "../../../assets/api/userExtra";
    import UserProfile from "./UserProfile";
    import api_file from "../../../assets/api/file";
    import {getAge, getBirth, GetCurrentFileUrl, getSex, workingYear} from "../../../assets/utils/general";
    import api_sys_department from "../../../assets/api/department";
    import api_sys_department_relation from "../../../assets/api/userDepartRelation";

    export default {
        name: "UserExtraManager",
        components:{
            TcBaseTable,SearchOutlined,EditFilled,FcForm,UserProfile,
            FileAddOutlined,PlusOutlined,SyncOutlined
        },
        data() {
            return {
                actionUrl:api_file.upload.url,
                accept:"image/png,image/jpeg",
                fileList:[],
                previewImage:null,

                accept2:"application/pdf",
                fileList2:[],
                previewPDF:null,

                departments:[],
                department:null,

                tableColumn:new sys_user_info_with_extra().getTableColumns(),
                formColumns:new sys_user_info_extra2().getFormColumns(),
                allUsers:[],
                dataSource:[],
                loading:false,
                defaultQuery:{
                    disableState:"1", //默认只查询在职人员
                },
                visible:false,
                visible2:false,
                visible3:false,
                mFormData:null,
                viewModel:true,
                currentRecord:{}
            }
        },
        methods: {
            init() {
                this.loading = true;
                this.defaultQuery.disableState = "1";
                this.department = null;
                userInfo.queryAll.requestPOSTUrlParam(this,this.defaultQuery,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.allUsers = res.data.data.filter(item=>item.id !=="2"); //删除超级管理员账号
                        this.dataSource = this.allUsers;
                    }
                    else{
                        this.allUsers = []
                    }
                })
            },
            departmentSelect(){
                let param  ={
                    departmentId:this.department
                };
                this.defaultQuery.disableState = "1";
                this.loading = true;
                api_sys_department_relation.queryAll.requestPOSTUrlParam(this,param,res=>{
                    this.loading = false;
                    if(res.data.flag && res.data.data.length>0){
                        let ids = [];
                        for(let i in res.data.data){
                            let {userId} = res.data.data[i];
                            ids.push(userId);
                        }
                        this.dataSource = this.allUsers.filter(item=>ids.indexOf(item.id) !==-1);
                    }
                    else{
                        this.dataSource = [];
                        this.$message.error(res.data.msg);
                    }
                });
            },
            radioChange(){
                this.tableColumn =
                    this.defaultQuery.disableState === "1"?
                        new sys_user_info_with_extra().getTableColumns():
                        new sys_user_info_with_extra_left().getTableColumns();
                this.department = null;
                this.loading = true;
                userInfo.queryAll.requestPOSTUrlParam(this,this.defaultQuery,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        res.data.data = res.data.data.filter(item=>item.id !=="2"); //删除超级管理员账号
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.dataSource = []
                    }
                })
            },
            getExtraData(id,callback,callback2){
                let data = {
                    userId:id
                };
                userExtra.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.mFormData = res.data.data[0];
                        this.mFormData.sex = getSex(this.mFormData.idCardNumber);
                        this.mFormData.birthday = getBirth(this.mFormData.idCardNumber);
                        this.mFormData.age = getAge(this.mFormData.idCardNumber);
                        this.mFormData.workingYear = workingYear(this.mFormData.inductionDate);
                        if(callback){callback()}
                    }
                    else{
                        if(callback2){callback2()}
                        this.$message.error("还未录入简历");
                        this.mFormData = new sys_user_info_extra2().getFormData();
                    }

                });
            },
            showDetail(record){
                this.currentRecord = record;
                this.getExtraData(record.id,()=>{
                    this.viewModel = true;
                    this.visible2 = true;
                });
            },
            editDetail(record){
                this.currentRecord = record;
                this.getExtraData(record.id,()=>{
                    this.viewModel = false;
                    this.visible = true;
                },()=>{
                    this.viewModel = false;
                    this.visible = true;
                });
            },
            fileAdd(record){
                this.currentRecord = record;
                this.visible3 = true;
                this.getExtraData(this.currentRecord.id,()=>{
                    this.previewImage = this.mFormData.headUrl?GetCurrentFileUrl(this.currentRecord.headUrl):null;
                    this.previewPDF = this.mFormData.fileUrl?GetCurrentFileUrl(this.mFormData.fileUrl):null;
                })
            },
            //上传照片
            handleChange({file}){
                if(file.status==="done"){//status: "done"
                    this.spinning = false;
                    //this.record[this.column.dataIndex] = file.response.data.fileUrl;
                    this.previewImage = GetCurrentFileUrl(file.response.data.fileUrl);
                    this.fileList = [];
                    this.currentRecord.headUrl = file.response.data.fileUrl;
                    userInfo.saveOrUpd.requestPOST(this,this.currentRecord,res=>{
                        if(res.data.flag){
                            this.$message.success(res.data.msg);
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }
            },
            //上传简历
            handleChange2({file}){
                if(file.status==="done"){//status: "done"
                    this.spinning = false;
                    // this.record[this.column.dataIndex] = file.response.data.fileUrl;
                    this.previewPDF = GetCurrentFileUrl(file.response.data.fileUrl);
                    this.fileList2 = [];
                    this.mFormData.fileUrl = file.response.data.fileUrl;
                    userExtra.saveOrUpdate.requestPOST(this,this.mFormData,res=>{
                        if(res.data.flag){
                            this.$message.success(res.data.msg);
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }
            },
            deleteAvatar(){
                this.currentRecord.headUrl = "";
                userInfo.saveOrUpd.requestPOST(this,this.currentRecord,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.previewImage = null
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            deletePDF(){
                this.mFormData.fileUrl = "";
                userExtra.saveOrUpdate.requestPOST(this,this.mFormData,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.previewPDF = null;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            saveData(record){
                record.userId = this.currentRecord.id;
                userExtra.saveOrUpdate.requestPOST(this,record,res=>{
                    if(res.data.flag){
                        this.visible = false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            }
        },
        created() {
            this.init()
            api_sys_department.queryAll.requestPOST(this,{},res=>{
                if(res.data.flag){
                    this.departments = res.data.data;
                }
            });
        }
    }
</script>

<style scoped>

</style>