<!--
* @program: TYH_office_automation 
* @author: ly
* @component:TimestampFormatter 
* @description: 
* @create: 2024-03-06 10:10
-->
<template>
    <div>{{presentValue}}</div>
</template>

<script>
    import moment from "moment";

    export default {
        name: "TimestampFormatter",
        props:["timestamp"],
        computed:{
            presentValue(){
                if(this.timestamp){
                    return moment(this.timestamp).locale('zh-cn').format('YYYY/MM/DD');
                }
                return "无";
            },
        }
    }
</script>

<style scoped>

</style>