<!--
* @program: office_automation 
* @author: ly
* @component:FinancialSubject
* @description: 会计大类绑定
* @create: 2022-01-25 15:41
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon>
                        <SyncOutlined/>
                    </template>
                </a-button>
                <a-tooltip  title="添加一级分类">
                    <a-button class="search-item" type="dashed" shape="circle"  @click="groupAdd">
                        <template #icon><PlusOutlined /></template>
                    </a-button>
                </a-tooltip>
            </div>
            <TwoParts ref="TwoParts" :enable-change="false"  :trans="[50,50]"  >
                <template #left>
                    <div style="display: flex">
                        <div style="border: 1px solid rgb(235, 237, 240);margin: 5px;width:50%">
                            <a-page-header  title="会计统计大类设定"/>
                            <a-tree style="text-align: left; margin-left: 20px"
                                    :tree-data="treeData"
                                    default-expand-all
                                    :replace-fields="replaceFields"
                                    showLine
                            >
                                <template #title="node">
                                    <div style="display: flex">
                                        <div style="margin: 5px">{{node.subjectName}}</div>
                                        <a-tooltip title="添加子类">
                                            <a-button style="padding: 0 5px"  type="link" @click="subAdd(node)"><PlusOutlined /></a-button>
                                        </a-tooltip>
                                        <a-tooltip title="名称编辑">
                                            <a-button style="padding: 0 5px" type="link" @click="nodeEdit(node)"><EditOutlined /></a-button>
                                        </a-tooltip>
                                        <a-tooltip v-if="node.childrens.length === 0"  title="关联统计类别">
                                            <a-button style="padding: 0 5px" type="link" @click="relateShow(node)"><LinkOutlined /></a-button>
                                        </a-tooltip>
                                        <a-popconfirm
                                                v-if="node.childrens.length === 0"
                                                ok-text="是"
                                                cancel-text="否"
                                                title="确定删除吗?"
                                                @confirm="nodeDelete(node)">
                                            <a-button style="padding: 0 5px"  type="link"><DeleteOutlined  style="color: red" class="pointer"/></a-button>
                                        </a-popconfirm>
                                    </div>
                                </template>
                            </a-tree>
                        </div>
                        <div v-if="subjectVisible" style="border: 1px solid rgb(235, 237, 240);margin: 5px;width:50%">
                            <a-page-header >
                                <template #title>
                                    <div style="display: flex;align-items: center;">
                                        <div>{{currentNode.subjectName}}关联的费用类别</div>
                                        <a-button class="search-item" type="dashed" shape="circle"  @click="refreshRelation">
                                            <template #icon><SyncOutlined /></template>
                                        </a-button>
                                        <a-tooltip  title="设定关联">
                                            <a-button class="search-item" type="dashed" shape="circle"  @click="setRelation">
                                                <template #icon><SettingOutlined /></template>
                                            </a-button>
                                        </a-tooltip>
                                    </div>
                                </template>
                            </a-page-header>
                            <ArBaseTable :argument="argument" :page-size="15" :view-model="true" >
                                <template #action="{index,record}">
                                    <div class="action-column">
                                        <a-popconfirm
                                                ok-text="是"
                                                cancel-text="否"
                                                title="确定删除吗?"
                                                @confirm="deleteRelation(index,record)">
                                            <a-button style="padding: 0 5px"  type="link"><DeleteOutlined  style="color: red" class="pointer"/></a-button>
                                        </a-popconfirm>
                                    </div>
                                </template>
                            </ArBaseTable>
                        </div>
                    </div>
                </template>
                <template #right>
                    <div v-if="subjectVisible && relationVisible">
                        <a-page-header style="border: 1px solid rgb(235, 237, 240)">
                            <template #title>
                                <div class="center-flex">
                                    <div>费用类别</div>
                                    <a-radio-group @change="selectChange" style="margin:10px" v-model:value="mode" >
                                        <a-radio value="1">全部</a-radio>
                                        <a-radio value="2">还未关联的类别</a-radio>
                                    </a-radio-group>
                                    <a-button class="search-item" type="default" shape="circle" @click="selectChange">
                                        <template #icon><SyncOutlined /></template>
                                    </a-button>
                                    <a-popconfirm
                                            ok-text="是"
                                            cancel-text="否"
                                            title="确认添加吗？"
                                            :disabled="checkedKeys.length===0"
                                            @confirm="addRelation">
                                        <a-button :disabled="checkedKeys.length===0" type="primary" style="margin:0 10px">
                                            添加类别
                                        </a-button>
                                    </a-popconfirm>
                                    <a-popconfirm
                                            ok-text="是"
                                            cancel-text="否"
                                            :disabled="checkedKeys.length===0"
                                            title="批量绑定会替换该类别之前绑定的类别，确认替换吗?"
                                            @confirm="bindRelation">
                                        <a-button :disabled="checkedKeys.length===0" type="primary" style="margin:0 10px">
                                            批量绑定
                                        </a-button>
                                    </a-popconfirm>
                                </div>
                            </template>
                        </a-page-header>
                        <a-spin :spinning="spinning">
                            <div>每个类别应该绑定于一个统计类别，重复绑定的话会导致该类别被多次统计</div>
                            <a-tree style="text-align: left;margin-left: 20px"
                                    :tree-data="treeData2"
                                    :replace-fields="replaceFields2"
                                    showLine
                                    checkable
                                    v-model:checkedKeys="checkedKeys">
                                <template #title="node">
                                    <div style="display: flex">
                                        <div style="margin: 5px">{{node.categoryName}}</div>
                                    </div>
                                </template>
                            </a-tree>
                        </a-spin>
                    </div>
                    <welcome2 v-else />
                </template>
            </TwoParts>
        </div>
    </div>
    <a-modal v-model:visible="visible"
             destroyOnClose
             @ok="saveData"
             cancelText="取消" okText="确定"
             :title="title">
        <span>类别名称：</span><a-input v-model:value="subjectName" style="width:150px" placeholder="请输入关键字" />
    </a-modal>
</template>

<script>
    import TwoParts from "../../_components/TwoParts";
    import {PlusOutlined,EditOutlined,LinkOutlined,SettingOutlined,
        DeleteOutlined,SyncOutlined} from "@ant-design/icons-vue";
    import subject from "../../../assets/api/subject";
    /*import subjectCategRelation from "../../../assets/api/subjectCategRelation";*/
    import category from "../../../assets/api/category";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import Welcome2 from "../../welcome2";

    export default {
        name: "FinancialSubject",
        components: {
            Welcome2,
            TwoParts,PlusOutlined,EditOutlined,LinkOutlined,SettingOutlined,
            DeleteOutlined,SyncOutlined,ArBaseTable
        },
        data(){
            return{
                replaceFields :{
                    children: 'childrens',
                    title: 'subjectName',
                    key:"id"
                },
                treeData:[],
                replaceFields2 :{
                    children: 'children',
                    title: 'categoryName',
                    key:"id"
                },
                treeData2:[],

                visible:false,
                argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:[
                        new Column("序号","_index",ColumnType.Index).setTableView(60),
                        new Column("类别名称","categoryName",ColumnType.String).setTableView(120),
                        new Column("操作","actions","actions").setTableView(80)
                    ], //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                },
                subjectName:null,
                title:null,
                currentNode:null,

                mode:"2",
                subjectVisible:false,
                relationVisible:false,
                spinning:false,
                checkedKeys:[],
                groupIds:[],//用于存放大类的id，上传关联时候需要去除这部分id
            }
        },
        methods:{
            init(){
                subject.queryAll.requestPOST(this,{},res=>{
                    if(res.data.flag){
                        this.treeData = res.data.data;
                        setSelectable(this.treeData)
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
        },
            initR(){
                this.relationVisible = false;
            },
            refresh(){
                this.init();
                this.initR();
            },
            groupAdd(){
                this.visible = true;
                this.title = "添加大类";
                this.currentNode = null;
            },
            subAdd(node){
                this.visible = true;
                this.title = "添加子类";
                this.currentNode = node;
                this.subjectVisible = false;
                this.relationVisible = false;
            },
            nodeEdit(node){
                this.visible = true;
                this.title = "名称修改";
                this.currentNode = node;
                this.subjectVisible = false;
                this.relationVisible = false;
            },
            relateShow(node){
                this.subjectVisible = true;
                this.relationVisible = false;
                this.currentNode = node;
                this.refreshRelation()
            },
            refreshRelation(){
                let data = {
                    subjectId:this.currentNode.id
                };
                this.argument.loading = true;
                subjectCategRelation.queryAll.requestPOSTUrlParam(this,data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                    }
                    else{
                        this.argument.dataSource = [];
                    }
                })
            },
            nodeDelete(node){
                let data = {
                    id:node.id
                };
                subject.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            deleteRelation(index,record){
                let data = {
                    id:record.id
                };
                subjectCategRelation.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.argument.dataSource.splice(index,1);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            saveData(){
                let data;
                switch (this.title) {
                    case "添加大类":{
                        data = {
                            "subjectName": this.subjectName
                        };
                        break
                    }
                    case "添加子类":{
                        data = {
                            "parentId": this.currentNode.id,
                            "subjectName": this.subjectName
                        };
                        break
                    }
                    case "修改名称":{
                        data = this.currentNode;
                        data.subjectName = this.subjectName;
                    }
                }
                subject.saveOrUpd.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.visible = false;
                        this.init();
                        this.$message.success(res.data.msg)
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            setRelation(){
                this.relationVisible = true;
                this.selectChange();
            },
            selectChange(){
                this.spinning = true;
                this.groupIds = [];
                this.checkedKeys = [];
                switch (this.mode) {
                    case "1":{//全部
                        category.queryOneLevel.requestPOST(this,{},res=>{
                            this.spinning = false;
                            if(res.data.flag){
                                this.treeData2 = res.data.data;
                                for(let i in this.treeData2){
                                    this.groupIds.push(this.treeData2[i].id);
                                }
                            }
                            else{
                                this.$message.error(res.data.msg);
                            }
                        });
                        break
                    }
                    case "2":{//还未未绑定的
                        category.noBindSuject.requestPOST(this,{},res=>{
                            this.spinning = false;
                            if(res.data.flag){
                                this.treeData2 = res.data.data;
                                let i = 0;
                                while (i < this.treeData2.length){
                                    if(this.treeData2[i].children.length>0){
                                        this.groupIds.push(this.treeData2[i].id);
                                        i++;
                                    }
                                    else{
                                        this.treeData2.splice(i,1);
                                    }
                                }
                            }
                            else{
                                this.$message.error(res.data.msg);
                            }
                        });
                        break
                    }
                }
            },
            bindRelation(){
                let keys=[];
                for(let i in this.checkedKeys){
                    if(this.groupIds.indexOf(this.checkedKeys[i]) === -1){
                        keys.push(this.checkedKeys[i]);
                    }
                }
                let data ={
                    subjectId:this.currentNode.id,
                    categoryIds:keys
                };
                subjectCategRelation.bindCategory.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.refreshRelation();
                        this.selectChange();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })

            },
            addRelation(){
                let keys=[];
                for(let i in this.checkedKeys){
                    if(this.groupIds.indexOf(this.checkedKeys[i]) === -1){
                        keys.push(this.checkedKeys[i]);
                    }
                }
                let arr = [];
                for(let i in keys){
                    arr.push(
                        {
                            "categoryId": keys[i],
                            "subjectId": this.currentNode.id
                        }
                    )
                }
                let data = {
                    subjectCategRelationVOS:arr
                };

                subjectCategRelation.batchSaveOrUpd.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.refreshRelation();
                        this.selectChange();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        created() {
            this.init();
            this.initR();
        }
    }
    function setSelectable(data){ //遍历树  获取id数组
        for(let i in data){
            if(data[i].childrens.length>0){  //存在子节点就递归
                setSelectable(data[i].childrens);
                data[i]["checkable"] = false;
                data[i]["selectable"] = false;
            }
            else{
                data[i]["checkable"] = true;
                data[i]["selectable"] = true;
            }
        }
    }
</script>

<style scoped>

</style>