/** 
* 自动生成文件，请勿修改 
* @name: 项目:人员管理-外协人员管理模块
* @description: 外协人员管理模块-增删改查
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const outSourceInfo = { 
	/** 1 summary:外协人员管理-批量删除	method:post */ 
	batchDelete : new APIObject("/api/outSourceInfo/batchDelete.do"), 
	/** 2 summary:外协人员管理-分页查询	method:post */ 
	queryPage : new APIObject("/api/outSourceInfo/queryPage.do"), 
	/** 3 summary:外协人员管理-所有数据查询	method:post */ 
	queryAll : new APIObject("/api/outSourceInfo/queryAll.do"), 
	/** 4 summary:外协人员管理-保存/修改	method:post */ 
	saveOrUpd : new APIObject("/api/outSourceInfo/saveOrUpd.do"), 
	/** 5 summary:外协人员管理-单条数据查询	method:post */ 
	queryOne : new APIObject("/api/outSourceInfo/queryOne.do"), 
	/** 6 summary:外协人员管理-批量保存/修改	method:post */ 
	batchSaveOrUpd : new APIObject("/api/outSourceInfo/batchSaveOrUpd.do"), 
	/** 7 summary:外协人员管理-单条删除	method:post */ 
	delete : new APIObject("/api/outSourceInfo/delete.do"), 
};
export default outSourceInfo;