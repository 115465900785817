/** 
* 自动生成文件，请勿修改 
* @name: 报销:经费支出统计模块
* @description: 报销-经费支出统计模块-数据统计查询
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const fundSummary = { 
	/** 1 summary:统计支出情况-按月统计每个项目的支出情况	method:post */ 
	statiByMonth : new APIObject("/api/fundSummary/statiByMonth.do"), 
	/** 2 summary:统计支出情况-按年统计每个项目的支出情况	method:post */ 
	statiByYear : new APIObject("/api/fundSummary/statiByYear.do"), 
	/** 3 summary:统计支出情况-	method:post */ 
	statistic : new APIObject("/api/fundSummary/statistic.do"), 
	/** 4 summary:统计支出情况-夏总说的那个大统计(导出)	method:get */ 
	exportExpend : new APIObject("/api/fundSummary/exportExpend.do"), 
	/** 5 summary:统计支出情况-按起始月统计各部门的支出情况	method:post */ 
	statiMonthDep : new APIObject("/api/fundSummary/statiMonthDep.do"), 
	/** 6 summary:统计支出情况-夏总说的那个大统计	method:post */ 
	statisticMonth : new APIObject("/api/fundSummary/statisticMonth.do"), 
	/** 7 summary:统计支出情况-按月统计每个部门的支出情况	method:post */ 
	statiByDepart : new APIObject("/api/fundSummary/statiByDepart.do"), 
};
export default fundSummary;