/** 
* 自动生成文件，请勿修改 
* @name: 项目:项目设计文档审核记录管理=============新增
* @description: 项目设计文档审核记录管理===新增
* @create: 2024/6/28 10:59:49
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const ItemDoc = { 
	/** 1 summary:保存/修改-单条	method:post */ 
	saveOrUpd : new APIObject("/api/ItemDoc/saveOrUpd.do"), 
	/** 2 summary:删除-批量	method:post */ 
	batchDelete : new APIObject("/api/ItemDoc/batchDelete.do"), 
	/** 3 summary:审核-单条	method:post */ 
	audit : new APIObject("/api/ItemDoc/audit.do"), 
	/** 4 summary:查询-分页	method:post */ 
	queryPage : new APIObject("/api/ItemDoc/queryPage.do"), 
	/** 5 summary:删除-单条	method:post */ 
	delete : new APIObject("/api/ItemDoc/delete.do"), 
	/** 6 summary:查询-所有数据	method:post */ 
	queryAll : new APIObject("/api/ItemDoc/queryAll.do"), 
	/** 7 summary:查询-单条数据	method:post */ 
	queryOne : new APIObject("/api/ItemDoc/queryOne.do"), 
};
export default ItemDoc;