/** 
* 自动生成文件，请勿修改 
* @name: 文件操作模块
* @description: 文件操作-上传/下载
* @create: 2024/6/28 10:59:48
* @swagger: 2.0
* 合计:4条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const file = { 
	/** 1 summary:文件操作模块-文件下载/查看	method:post */ 
	download : new APIObject("/api/file/download.do"), 
	/** 2 summary:文件操作模块-多个文件上传	method:post */ 
	uploads : new APIObject("/api/file/uploads.do"), 
	/** 3 summary:文件操作模块-文件删除(可批量，可单个)	method:post */ 
	batchDeleteFile : new APIObject("/api/file/batchDeleteFile.do"), 
	/** 4 summary:文件操作模块-单个文件上传	method:post */ 
	upload : new APIObject("/api/file/upload.do"), 
};
export default file;